import { Suspense } from 'react';

import CenteredSpinner from './CenteredSpinner';
import { LoaderProps } from './Loader';

function SuspenseLoader(props: Omit<LoaderProps, 'isLoading'>) {
  const { children, className, size } = props;

  return (
    <Suspense fallback={<CenteredSpinner className={className} size={size} />}>
      {children}
    </Suspense>
  );
}

export default SuspenseLoader;
