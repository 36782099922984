import CenteredSpinner from './CenteredSpinner';

export interface LoaderProps {
  children?: React.ReactNode
  className?: string
  isLoading: boolean
  size?: number | string
}

function Loader(props: LoaderProps) {
  const {
    children,
    className,
    isLoading,
    size,
  } = props;

  if (isLoading) return <CenteredSpinner className={className} size={size} />;
  return children;
}

export default Loader;
