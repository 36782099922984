import { Typography } from '@mui/material';
import styled from 'styled-components';
import { SecondaryLinkButton } from 'styles/styles';

export const Congratulations = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  font-weight: bold;
  color: white;
  text-transform: uppercase;

  @media print {
    color: black;
  }
`;

export const CongratulationsName = styled.span`
  color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};

  @media print {
    color: black;
  }
`;

export const NowVIP = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  
  @media print {
    color: black;
  }
`;

export const PassSentText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: white;
  line-height: 1.2;

  @media print {
    color: black;
  }
`;

export const ResendButton = styled(SecondaryLinkButton)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: white;

  @media print {
    display: none;
  }
`;

export const SubtextMovon = styled(Typography)`
  font-size: 12px;
  color: white;

  @media print {
    color: black;
  }
`;
