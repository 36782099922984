const policiesEn: { [name: string]: string } = {
  privacy_policy: 'Privacy policy',
  privacy_policy_1_content: 'MovOn.VIP (the "Site") is operated by Novom Interactive (the "Operator"). This privacy policy (the "Policy") describes how the Operator collects, uses and discloses your personal information. By accessing and using the Site, you agree to be bound by this Policy. If you do not agree to this Policy, you should not access or use the Site.',
  privacy_policy_1_title: '1. Introduction',
  privacy_policy_2_content: 'The Operator collects the following personal information from you:'
    + '<ul>'
    + '<li><b>Personal information you voluntarily provide</b>: This includes your email address, telephone number, password and other information you provide when you create an account on the Site or when you use certain features of the Site.</li>'
    + '<li><b>Personal information collected automatically</b>: When you use the Site, the Operator automatically collects certain personal information about you, such as your IP address, Web browser and operating system. This information is used to improve the Site\'s performance and for analysis purposes.</li>'
    + '<li><b>Personal information from third parties</b>: The Operator may not collect personal information about you from third parties, such as social networks.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Collection of Personal Information',
  privacy_policy_3_content: 'The Operator uses your personal information for the following purposes:'
    + '<ul>'
    + '<li><b>To provide you with the Site\'s services</b>: The Operator uses your Personal Information to provide you with the Site\'s services, such as creating an account, publishing content, and communicating with other users.</li>'
    + '<li><b>To improve the Site</b>: The Operator uses your personal information to improve the Site and its services.</li>'
    + '<li><b>To contact you</b>: The Operator may use your personal information to contact you about the Site or your accounts.</li>'
    + '<li><b>For Marketing Purposes</b>: The Operator may use your personal information to send you marketing communications, such as thank you courriels and satisfaction surveys. In all communications, the unsubscribe process is simple and accessible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Use of personal information',
  privacy_policy_4_content: 'The Operator may disclose your personal information to the following third parties:'
    + '<ul>'
    + '<li><b>Third Party Service Providers</b>: The Operator may disclose your Personal Information to third party service providers who assist the Operator in operating the Site, such as web hosting providers and payment service providers.</li>'
    + '<li><b>Governmental Authorities</b>: The Operator may disclose your personal information to governmental authorities if required to do so by law or if the Operator believes in good faith that disclosure is necessary to protect its rights or the rights of third parties.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Disclosure of personal information',
  privacy_policy_5_content: 'You may exercise the following rights with respect to your personal information:'
    + '<ul>'
    + '<li><b>Access to your personal information</b>: You have the right to access your personal information held by the Operator.</li>'
    + '<li><b>Correction of your personal information</b>: You have the right to have inaccurate or incomplete personal information held by the Operator corrected.</li>'
    + '<li><b>Deleting your personal information</b>: You have the right to request that the Operator delete your personal information.</li>'
    + '<li><b>Limiting the processing of your personal information</b>: You have the right to request that the Operator limit the processing of your personal information.</li>'
    + '<li><b>Objection to the processing of your personal information</b>: You have the right to object to the processing of your personal information by the Operator.</li>'
    + '</ul>'
    + 'To exercise your rights, please contact Eric Foley at <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Your choices',
  privacy_policy_6_content: 'The Operator takes reasonable security measures to protect your personal information against loss, theft, misuse, unauthorized disclosure, access, alteration or destruction.',
  privacy_policy_6_title: '6. Security of personal information',
  privacy_policy_7_content: 'The Operator will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or for such longer period as may be required or permitted by law.',
  privacy_policy_7_title: '7. Retention of Personal Information',
  privacy_policy_8_content: 'The Operator may amend this Policy at any time by posting the amended Policy on the Site. Changes to the Policy will be effective immediately upon posting. You are responsible for regularly reviewing the Policy to ensure that you are aware of any changes.',
  privacy_policy_8_title: '8. Changes to the Policy',
  privacy_policy_9_content: 'If you have any questions regarding this Policy, please contact Eric Foley at <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contact',
};

export default policiesEn;
