interface StoredTransaction {
  transactionId: string
  isFreemium: boolean
}

const KEY = 'saved-transaction';

export function storeTransaction(transaction: StoredTransaction) {
  localStorage.setItem(KEY, JSON.stringify(transaction));
}

export function getStoredTransaction(): StoredTransaction | null {
  const stored = localStorage.getItem(KEY);
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch {
      return null;
    }
  }
  return null;
}

export function clearStoredTransaction() {
  localStorage.removeItem(KEY);
}
