const termsEs: { [name: string]: string } = {
  terms_conditions: 'Condiciones generales',
  terms_conditions_1_content: 'Bienvenido a la aplicación web MovOn.VIP (el "Sitio"). El Sitio está gestionado por Novom Interactive (el "Operador"). Al acceder y utilizar el Sitio, acepta las presentes Condiciones de uso (las "Condiciones"). Si no está de acuerdo con estos Términos, no debe acceder ni utilizar el Sitio.',
  terms_conditions_1_title: '1. Introducción',
  terms_conditions_2_content: 'MovOn.VIP se compromete a cumplir con la Ley 25 de Quebec y el Reglamento General de Protección de Datos (RGPD) con respecto a la recopilación, uso y divulgación de su información personal. Para obtener más información sobre nuestras prácticas de protección de datos, consulte nuestra Política de privacidad.',
  terms_conditions_2_title: '2. Cumplimiento de la Ley 25 y del RGPD',
  terms_conditions_3_content: 'MovOn.VIP nunca venderá su información personal a terceros. Podremos compartir su información personal con terceros proveedores de servicios que nos ayuden a operar el Sitio, pero sólo lo haremos en la medida necesaria para prestar dichos servicios. ',
  terms_conditions_3_title: '3. No venta de datos a terceros',
  terms_conditions_4_content: 'Todo el contenido del Sitio, incluyendo texto, imágenes, vídeo y software, está protegido por derechos de autor y otros derechos de propiedad intelectual del Operador o de sus licenciantes. No puede utilizar el contenido del Sitio sin el consentimiento previo por escrito del Operador.',
  terms_conditions_4_title: '4. Derechos de propiedad intelectual',
  terms_conditions_5_content: 'MovOn.VIP le concede una licencia no exclusiva e intransferible para utilizar el Sitio. No está autorizado a utilizar el Sitio con fines ilegales o perjudiciales, ni a violar los derechos del Operador o de terceros.',
  terms_conditions_5_title: '5. Autorización concedida a los usuarios',
  terms_conditions_6_content: 'MovOn.VIP no se hace responsable de los daños directos, indirectos, accesorios, especiales o consecuentes, incluida la pérdida de beneficios, clientela o datos, resultantes del uso o de la imposibilidad de usar el Sitio, incluso si MovOn.VIP ha sido informado de la posibilidad de tales daños.',
  terms_conditions_6_title: '6. Limitación de responsabilidad',
  terms_conditions_7_content: 'Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de la Provincia de Quebec. Cualquier disputa que surja de o en relación con estos Términos se someterá a la jurisdicción exclusiva de los tribunales competentes de la provincia de Quebec.',
  terms_conditions_7_title: '7. Legislación aplicable y jurisdicción',
  terms_conditions_8_content: 'MovOn.VIP podrá modificar estas Condiciones en cualquier momento publicando las Condiciones modificadas en el Sitio. Las Condiciones modificadas entrarán en vigor en el momento de su publicación. Usted es responsable de leer regularmente las Condiciones para asegurarse de que está al tanto de cualquier cambio.',
  terms_conditions_8_title: '8. Modificaciones de las Condiciones',
  terms_conditions_9_content: 'La versión francesa de las presentes Condiciones prevalecerá sobre cualquier otra traducción.',
  terms_conditions_9_title: '9. Lengua de traducción',
  terms_conditions_10_content: 'Si tiene alguna pregunta sobre estas Condiciones, póngase en contacto con MovOn.VIP en <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contacto',
  terms_conditions_copyright: '© {year} MovOn.VIP',
};

export default termsEs;
