import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import iconError from 'assets/images/icon_error.png';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import {
  Container,
  Logo,
  Title,
} from 'modules/Home/styles';
import { AlertContext } from 'providers/AlertProvider/AlertContext';
import { useCallback, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from 'styles/styles';
import { useLocation } from 'wouter';

import { AlertIcon, AlertIconContainer } from './styles';

function AlertPage() {
  const theme = useTheme();
  const { message, setMessage } = useContext(AlertContext);
  const [, setLocation] = useLocation();

  const handleBackButtonEvent = useCallback((e: PopStateEvent) => {
    e.preventDefault();
    setMessage(undefined);
    setLocation('/tickets');
  }, [setLocation, setMessage]);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
    setMessage(undefined);
    setLocation('/tickets');
  }, [setLocation, setMessage]);

  useEffect(() => {
    if (message) {
      window.addEventListener('popstate', handleBackButtonEvent);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBackButtonEvent, handleBeforeUnload, message]);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs padding="1rem">
          <Logo />
        </Grid>
        <Grid item padding="1rem ">
          <LanguageSelector />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={2}
        flexGrow={isDesktop ? 0 : 1}
        alignItems="center"
      >

        <Grid item textAlign={isDesktop ? 'start' : 'center'} marginTop="auto">
          <AlertIconContainer>
            <AlertIcon src={iconError} alt="Error" />
          </AlertIconContainer>
        </Grid>
        <Grid item width={isDesktop ? '100%' : '75%'} textAlign="center">
          <Title>
            <FormattedMessage id="alert_something_wrong" />
          </Title>
        </Grid>
        <Grid item width={isDesktop ? '100%' : '75%'} textAlign="center">
          <FormattedMessage id={`alert_${message}`} />
        </Grid>
        <Grid item textAlign={isDesktop ? 'start' : 'center'} marginTop="auto" marginBottom="auto">
          <PrimaryButton
            onClick={() => {
              setLocation('/tickets');
              setMessage(undefined);
            }}
          >
            <FormattedMessage id="alert_return" />
          </PrimaryButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AlertPage;
