const policiesEs: { [name: string]: string } = {
  privacy_policy: 'Política de privacidad',
  privacy_policy_1_content: 'MovOn.VIP (el "Sitio") está gestionado por Novom Interactive (el "Operador"). Esta política de privacidad (la "Política") describe cómo el Operador recopila, utiliza y divulga su información personal. Al acceder y utilizar el Sitio, usted acepta quedar vinculado por esta Política. Si no está de acuerdo con esta Política, no debe acceder ni utilizar el Sitio.',
  privacy_policy_1_title: '1. Introducción',
  privacy_policy_2_content: 'El Operador recoge la siguiente información personal de usted:'
    + '<ul>'
    + '<li><b>Información personal que usted proporciona voluntariamente</b>: Esto incluye su dirección de correo electrónico, número de teléfono, contraseña y otra información que usted proporciona cuando crea una cuenta en el Sitio o cuando utiliza ciertas funciones del Sitio.</li>'
    + '<li><b>Información personal recopilada automáticamente</b>: Cuando utiliza el Sitio, el Operador recopila automáticamente cierta información personal sobre usted, como su dirección IP, navegador web y sistema operativo. Esta información se utiliza para mejorar el rendimiento del Sitio y con fines de análisis.</li>'
    + '<li><b>Información personal de terceros</b>: El Operador no puede recopilar información personal sobre usted de terceros, como redes sociales.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Recogida de información personal',
  privacy_policy_3_content: 'El Operador utiliza su información personal para los siguientes fines:'
    + '<ul>'
    + '<li><b>Para proporcionarle servicios en el Sitio</b>: El Operador utiliza su información personal para proporcionarle servicios en el Sitio, como crear una cuenta, publicar contenido y comunicarse con otros usuarios.</li>'
    + '<li><b>Para mejorar el Sitio</b>: El Operador utiliza su información personal para mejorar el Sitio y sus servicios.</li>'
    + '<li><b>Para ponerse en contacto con usted</b>: El Operador puede utilizar sus Datos Personales para ponerse en contacto con usted en relación con el Sitio o sus cuentas.</li>'
    + '<li><b>Para fines de marketing</b>: El Operador puede utilizar su Información Personal para enviarle comunicaciones de marketing, como correos electrónicos de agradecimiento y encuestas de satisfacción. En todas las comunicaciones, el proceso para darse de baja es sencillo y accesible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Uso de la información personal',
  privacy_policy_4_content: 'El Operador puede revelar su información personal a los siguientes terceros:'
    + '<ul>'
    + '<li><b>Terceros proveedores de servicios</b>: El Operador puede revelar su Información personal a terceros proveedores de servicios que ayudan al Operador a operar el Sitio, como proveedores de alojamiento web y proveedores de servicios de pago.</li>'
    + '<li><b>Autoridades gubernamentales</b>:  El Operador puede revelar su Información Personal a autoridades gubernamentales si así lo requiere la ley o si el Operador cree de buena fe que la revelación es necesaria para proteger sus derechos o los derechos de terceros.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Divulgación de información personal',
  privacy_policy_5_content: 'Puede ejercer los siguientes derechos en relación con su información personal:'
    + '<ul>'
    + '<li><b>Acceso a su información personal</b>: Tiene derecho a acceder a su información personal en poder del Operador.</li>'
    + '<li><b>Rectificación de sus datos personales</b>: Tiene derecho a que se rectifiquen los datos personales inexactos o incompletos que obren en poder del Operador.</li>'
    + '<li><b>Eliminación de su información personal</b>: Tiene derecho a solicitar que el Operador elimine su información personal.</li>'
    + '<li><b>Limitación del procesamiento de su información personal</b>: Tiene derecho a solicitar que el Operador limite el procesamiento de su información personal.</li>'
    + '<li><b>Oposición al tratamiento de sus datos personales</b>: Tiene derecho a oponerse al tratamiento de sus datos personales por parte del Operador.</li>'
    + '</ul>'
    + 'Para ejercer sus derechos, póngase en contacto Eric Foley en <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Sus opciones',
  privacy_policy_6_content: 'El Operador toma medidas de seguridad razonables para proteger su información personal contra pérdida, robo, mal uso, divulgación no autorizada, acceso, alteración o destrucción.',
  privacy_policy_6_title: '6. Seguridad de la información personal',
  privacy_policy_7_content: 'El Operador conservará su información personal durante el tiempo que sea necesario para cumplir con los fines para los que fue recopilada, o durante el período más largo que pueda ser requerido o permitido por la ley.',
  privacy_policy_7_title: '7. Conservación de la información personal',
  privacy_policy_8_content: 'El Operador puede modificar esta Política en cualquier momento publicando la Política modificada en el Sitio. Los cambios en la Política entrarán en vigor inmediatamente después de su publicación. Usted es responsable de revisar regularmente la Política para asegurarse de que conoce cualquier cambio.',
  privacy_policy_8_title: '8. Cambios en la Política',
  privacy_policy_9_content: 'Si tiene alguna pregunta sobre esta Política, póngase en contacto con Eric Foley en <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contacto',
};

export default policiesEs;
