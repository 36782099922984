import { skipToken } from '@apollo/client';
import { useAse, useAseMessages } from '@novominteractive/ase-react-apollo';
import {
  QueueType,
  useAseLoginMutation,
  useGetTicketFromTransactionSuspenseQuery,
  useGetWaitingLineSuspenseQuery,
} from 'lib/graphql/graphql';
import { storeTransaction } from 'lib/stores/transactions';
import {
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import FreemiumLayout from '../components/FreemiumLayout/FreemiumLayout';
import TicketNumber from '../components/TicketNumber/TicketNumber';
import {
  Spacer,
  Time,
  Welcome,
} from './styles';

interface TicketQueueType {
  queueType: QueueType;
}

function Wait() {
  const [, setLocation] = useLocation();
  const [aseLogin] = useAseLoginMutation();
  const { loggedIn, login } = useAse();

  const transactionId = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('id');
  }, []);

  const { data, refetch } = useGetTicketFromTransactionSuspenseQuery(
    transactionId ? {
      variables: { transactionId },
      fetchPolicy: 'network-only',
      queryKey: 'get-transaction-wait',
    } : skipToken,
  );

  const tickets = useMemo(() => data?.getTicketFromTransaction.tickets || [], [data]);
  const waitingLineId = tickets[0]?.waitingLineId;

  useEffect(() => {
    async function connectToAse() {
      if (!loggedIn) {
        const { data: aseLoginData } = await aseLogin();
        if (aseLoginData?.aseLogin) {
          login(aseLoginData.aseLogin);
        }
      }
    }
    connectToAse();
  }, [loggedIn, login, aseLogin]);

  useAseMessages<TicketQueueType>(
    `${tickets[0]?.waitingLineId}/${tickets[0]?.id}`,
    (payload) => {
      if (payload.queueType === QueueType.Called) {
        setLocation(`/called?id=${transactionId}`);
      }
    },
    !loggedIn || !tickets[0]?.id || !tickets[0]?.waitingLineId,
  );

  const { data: waitingLineData } = useGetWaitingLineSuspenseQuery(
    waitingLineId ? {
      variables: { id: waitingLineId },
    } : skipToken,
  );
  const waitingLine = waitingLineData?.getWaitingLine;

  useEffect(() => {
    if (tickets.length > 0 && transactionId) {
      storeTransaction({ isFreemium: true, transactionId });
    }
  }, [tickets.length, transactionId]);

  useEffect(() => {
    if (!transactionId || !waitingLine) {
      setLocation('/tickets');
    }
  }, [setLocation, transactionId, waitingLine]);

  useEffect(() => {
    if (tickets.length > 0 && tickets[0].queueType === QueueType.Called) {
      setLocation(`/called?id=${transactionId}`);
    }
  }, [setLocation, tickets, transactionId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60_000); // every minutes

    return () => clearInterval(intervalId);
  }, [refetch]);

  if (!transactionId || !waitingLine) {
    return null;
  }

  const { displayId } = tickets[0];

  return (
    <FreemiumLayout>
      <div>
        <Welcome>
          <FormattedMessage id="waiting_line_waiting_welcome" />
        </Welcome>
        <Time>
          <FormattedMessage id="waiting_line_waiting" />
        </Time>
      </div>
      <TicketNumber ticketNumber={displayId.toString()} />
      <Spacer />
    </FreemiumLayout>
  );
}

export default Wait;
