import { CircularProgress } from '@mui/material';
import { Centered } from 'styles/styles';

export interface CenteredSpinnerProps {
  className?: string
  size?: number | string
}

function CenteredSpinner(props: CenteredSpinnerProps) {
  const { className, size = '5rem' } = props;

  return (
    <Centered>
      <CircularProgress className={className} size={size} />
    </Centered>
  );
}

export default CenteredSpinner;
