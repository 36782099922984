import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { AlertContext } from 'providers/AlertProvider/AlertContext';
import { EventContext } from 'providers/EventProvider/EventContext';
import { PropsWithChildren, useContext } from 'react';

import Layout from './Layout';

function EventLayout(props: PropsWithChildren) {
  const { children } = props;

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);
  const { message } = useContext(AlertContext);

  const eventLanguage = event.languages[0] as Languages;

  if (message) return null;

  return (
    <Layout title={event.name[language] || event.name[eventLanguage]}>
      {children}
    </Layout>
  );
}

export default EventLayout;
