import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';
import SuspenseLoader from 'components/Loading/SuspenseLoader';
import SavedTransactionChecker from 'components/SavedTransactionChecker';
import RoutesEnum from 'lib/constants/routesEnum';
import ConfirmationPass from 'modules/ConfirmationPass/ConfirmationPass';
import Home from 'modules/Home/Home';
import Login from 'modules/Login/Login';
import Onboarding from 'modules/OnBoarding/Onboarding';
import Support from 'modules/Support/Support';
import AlertProvider from 'providers/AlertProvider/AlertProvider';
import EventProvider from 'providers/EventProvider/EventProvider';
import { Route, Router, Switch } from 'wouter';

import TicketingRoutes from './TicketingRoutes';

function Routes() {
  return (
    <Router>
      <ErrorBoundary>
        <SuspenseLoader>
          <SavedTransactionChecker>
            <Switch>
              <Route path={RoutesEnum.HOME} component={Home} />
              <Route path="/event/:eventId" component={Home} />
              <Route path={RoutesEnum.LOGIN} component={Login} />
              <Route path={RoutesEnum.ONBOARDING} component={Onboarding} />
              <Route path="/event/:eventId" nest>
                <EventProvider>
                  <AlertProvider>
                    <TicketingRoutes />
                  </AlertProvider>
                </EventProvider>
              </Route>
              <Route path={RoutesEnum.CONFIRMATION} component={ConfirmationPass} />
              <Route path={RoutesEnum.SUPPORT} component={Support} />
              <Route component={Home} />
            </Switch>
          </SavedTransactionChecker>
        </SuspenseLoader>
      </ErrorBoundary>
    </Router>
  );
}

export default Routes;
