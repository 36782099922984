import { Paper } from '@mui/material';
import { styled } from 'styled-components';

export const FormContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  padding: 1rem;
  align-items: center;
`;

export const FullWidthForm = styled.form`
  display: flex;
  flex: 1;
  width: 100%;
`;
