import styled from 'styled-components';

export const QuantityInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuantitySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const QuantityText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: black;
  font-weight: bold;
`;

export const QuantityAccentText = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.palette.primary.main};
`;
