const termsFr: { [name: string]: string } = {
  terms_conditions: 'Termes & conditions',
  terms_conditions_1_content: 'Bienvenue sur l\'application web de MovOn.VIP (le "Site"). Le Site est exploité par Novom Interactive (l\'"Opérateur"). En accédant et en utilisant le Site, vous acceptez d\'être lié par les présentes Conditions Générales d\'Utilisation (les "Conditions"). Si vous n\'acceptez pas les présentes Conditions, vous ne devez pas accéder au Site ou l\'utiliser.',
  terms_conditions_1_title: '1. Introduction',
  terms_conditions_2_content: 'MovOn.VIP s\'engage à respecter la loi 25 du Québec et le Règlement général sur la protection des données (RGPD) en ce qui concerne la collecte, l\'utilisation et la divulgation de vos renseignements personnels. Pour plus d\'informations sur nos pratiques en matière de protection des données, veuillez consulter notre Politique de confidentialité.',
  terms_conditions_2_title: '2. Respect de la loi 25 et du RGPD',
  terms_conditions_3_content: 'MovOn.VIP ne vendra jamais vos renseignements personnels à des tiers. Nous pouvons partager vos renseignements personnels avec des fournisseurs de services tiers qui nous aident à exploiter le Site, mais nous ne le ferons que dans la mesure où cela est nécessaire pour fournir ces services.',
  terms_conditions_3_title: '3. Aucune vente de données à des tiers',
  terms_conditions_4_content: 'Tout le contenu du Site, y compris les textes, les images, les vidéos et les logiciels, est protégé par le droit d\'auteur et d\'autres droits de propriété intellectuelle de l\'Opérateur ou de ses concédants de licence. Vous ne pouvez pas utiliser le contenu du Site sans le consentement écrit préalable de l\'Opérateur.',
  terms_conditions_4_title: '4. Propriété intellectuelle',
  terms_conditions_5_content: 'MovOn.VIP vous accorde une licence non exclusive et non transférable d\'utilisation du Site. Vous n\'êtes pas autorisé à utiliser le Site à des fins illégales ou nuisibles, ou à violer les droits de l\'Opérateur ou de tiers.',
  terms_conditions_5_title: '5. Autorisation octroyée aux utilisateurs',
  terms_conditions_6_content: 'MovOn.VIP ne peut être tenu responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs, y compris les pertes de profits, de clientèle ou de données, résultant de l\'utilisation ou de l\'impossibilité d\'utiliser le Site, même si MovOn.VIP a été informé de la possibilité de tels dommages.',
  terms_conditions_6_title: '6. Limitation de responsabilité',
  terms_conditions_7_content: 'Les présentes Conditions sont régies par les lois de la province de Québec et interprétées conformément à celles-ci. Tout litige découlant de ou lié aux présentes Conditions sera soumis à la juridiction exclusive des tribunaux compétents de la province de Québec.',
  terms_conditions_7_title: '7. Lois applicables et juridiction',
  terms_conditions_8_content: 'MovOn.VIP peut modifier les présentes Conditions à tout moment en publiant les Conditions modifiées sur le Site. Les Conditions modifiées entreront en vigueur dès leur publication. Vous êtes responsable de la lecture régulière des Conditions pour vous assurer que vous êtes au courant de toutes les modifications.',
  terms_conditions_8_title: '8. Modifications des Conditions',
  terms_conditions_9_content: 'La version française des présentes Conditions prévaudra sur toute autre traduction.',
  terms_conditions_9_title: '9. Langue de traduction',
  terms_conditions_10_content: 'Si vous avez des questions concernant les présentes Conditions, veuillez contacter MovOn.VIP à <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contact',

  terms_conditions_copyright: '© {year} MovOn.VIP',
};

export default termsFr;
