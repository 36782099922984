import { Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import cloudinaryClient from 'lib/cloudinary/CloudinaryClient';
import { useGetAllEventWaitingLinesLazyQuery, WaitingLineStatus } from 'lib/graphql/graphql';
import { EventContext } from 'providers/EventProvider/EventContext';
import {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, ImageContainer, RadiantImage } from './styles';
import Ticket from './Ticket';

function Tickets() {
  const event = useContext(EventContext);
  const [getAllWaitingLinesQuery] = useGetAllEventWaitingLinesLazyQuery();
  const eventImage = cloudinaryClient.image(event.image as string);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['waitingLines'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllWaitingLinesQuery({
        fetchPolicy: 'network-only',
        variables: { eventId: event.id, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllEventWaitingLines?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    setInterval(() => {
      refetch();
    }, 60000);
  }, [refetch]);

  const waitingLines = useMemo(() => {
    if (data && !isLoading) {
      if (!data.pages[0]?.getAllEventWaitingLines) {
        return [];
      }
      const tempWaitingLines = data?.pages.flatMap(
        (page) => page?.getAllEventWaitingLines.waitingLines || [],
      ) || [];
      return tempWaitingLines;
    }
    return [];
  }, [isLoading, data]);

  const filteredWaitingLines = useMemo(() => (
    waitingLines.filter((waitingLine) => waitingLine.status !== WaitingLineStatus.Hidden)
  ), [waitingLines]);

  const sortedWaitingLines = useMemo(() => (
    [...filteredWaitingLines].sort((a, b) => (
      a.status === WaitingLineStatus.Active
      && b.status !== WaitingLineStatus.Active ? -1 : 1
    ))
  ), [filteredWaitingLines]);

  if (isLoading) return <CenteredSpinner />;

  if (error) return <ErrorPage title={error.message} />;

  return (
    <>
      {eventImage ? (
        <ImageContainer cldImg={eventImage} />
      ) : (
        <RadiantImage />
      )}
      <Container>
        {sortedWaitingLines.map((waitingLine) => (
          <Ticket
            key={waitingLine.id}
            waitingLine={waitingLine}
          />
        ))}
        {(!sortedWaitingLines || sortedWaitingLines.length === 0) && (
          <Typography component="p" variant="body1" color="white">
            <FormattedMessage id="event_no_waiting_line_available" />
          </Typography>
        )}
      </Container>
    </>
  );
}

export default Tickets;
