enum RoutesEnum {
  HOME = '/',
  LOGIN = '/login',
  ONBOARDING = '/onboarding',
  TICKETS = '/tickets',
  CART = '/cart',
  CONFIRMATION = '/confirmation',
  PASS = '/pass',
  SUPPORT = '/support',
}

export default RoutesEnum;
