import { ButtonBase } from '@mui/material';
import { styled } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChannelButton = styled(ButtonBase)`
  padding: 0.2rem 0.3rem;
  border-radius: 4px;
`;
