import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@mui/material';
import {
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import LanguageContext, { Languages } from '../../lib/providers/Language/LanguageContext';
import {
  DropdownText,
  DropdownTrigger,
  IconContainer,
  MenuStyled,
} from './styles';

function LanguageSelector() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const anchor = useRef<HTMLButtonElement>(null);

  const setLocale = useCallback((newLocale: Languages) => {
    setDropdownOpen(false);
    setLanguage(newLocale);
  }, [setLanguage]);

  const toggleDropdown = useCallback(() => { setDropdownOpen((prev) => !prev); }, []);

  return (
    <>
      <DropdownTrigger
        ref={anchor}
        onClick={toggleDropdown}
      >
        <DropdownText>
          {language.toUpperCase()}
        </DropdownText>
        <IconContainer>
          <FontAwesomeIcon icon={faChevronDown} />
        </IconContainer>
      </DropdownTrigger>
      <MenuStyled
        anchorEl={anchor.current}
        open={dropdownOpen}
        onClose={toggleDropdown}
        MenuListProps={{ sx: { py: 0 } }}
      >
        {Object.values(Languages).map((lang) => (
          <MenuItem
            key={lang}
            selected={language === lang}
            onClick={() => setLocale(lang)}
          >
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </MenuStyled>
    </>
  );
}

export default LanguageSelector;
