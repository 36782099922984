import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import Authenticator from 'components/Authenticator/Authenticator';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import FormErrors from 'components/FormErrors/FormErrors';
import { CommunicationChannel, useSendMagicLoginLinkMutation } from 'lib/graphql/graphql';
import { useCallback, useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ChannelButton, Container } from './styles';

function Login() {
  const { loggedIn, logout } = useContext(AuthenticatorContext);
  const { register, handleSubmit, formState } = useForm<{ value: string }>();
  const { errors } = formState;

  const [sendMagicLink, { loading }] = useSendMagicLoginLinkMutation();
  const [channel, setChannel] = useState(CommunicationChannel.Email);

  const onSubmit: SubmitHandler<{ value: string }> = useCallback((formData) => {
    const { value } = formData;
    sendMagicLink({ variables: { channel, id: value } });
  }, [channel, sendMagicLink]);

  // TODO UI is only for testing
  return (
    <Authenticator>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {!loggedIn && (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  variant="standard"
                  label={channel === CommunicationChannel.Email ? 'Email' : 'Tel'}
                  type={channel === CommunicationChannel.Email ? 'email' : 'tel'}
                  {...register('value', { required: true })}
                  InputProps={{
                    endAdornment: (
                      <ChannelButton
                        type="button"
                        onClick={() => setChannel(
                          channel === CommunicationChannel.Email
                            ? CommunicationChannel.Sms
                            : CommunicationChannel.Email,
                        )}
                      >
                        {channel === CommunicationChannel.Email ? 'Use SMS instead' : 'Use email instead'}
                      </ChannelButton>
                    ),
                  }}
                />
                <FormErrors error={errors.value} />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <LoadingButton
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  variant="contained"
                >
                  <span>Send link</span>
                </LoadingButton>
              </Grid>
            </>
            )}
            {loggedIn && (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <p>You are logged in 👌</p>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  type="button"
                  onClick={logout}
                  variant="outlined"
                >
                  <span>Logout</span>
                </Button>
              </Grid>
            </>
            )}
          </Grid>
        </form>
      </Container>
    </Authenticator>
  );
}

export default Login;
