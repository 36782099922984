import SuspenseLoader from 'components/Loading/SuspenseLoader';
import cloudinaryClient from 'lib/cloudinary/CloudinaryClient';
import { EventContext } from 'providers/EventProvider/EventContext';
import { PropsWithChildren, useContext, useMemo } from 'react';
import { EventLogo } from 'styles/styles';

import { Container, Content } from './styles';

function FreemiumLayout(props: PropsWithChildren) {
  const { children } = props;

  const { image } = useContext(EventContext);

  const eventImage = useMemo(() => cloudinaryClient.image(image as string), [image]);

  return (
    <Container>
      <EventLogo cldImg={eventImage} />
      <Content>
        <SuspenseLoader>
          {children}
        </SuspenseLoader>
      </Content>
    </Container>
  );
}

export default FreemiumLayout;
