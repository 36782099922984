import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import FormErrors from 'components/FormErrors/FormErrors';
import LayoutWithBackground from 'components/Layout/LayoutWithBackground';
import { useSendSupportMessageMutation } from 'lib/graphql/graphql';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CenteredContainer } from 'styles/styles';

import { FormContainer, FullWidthForm } from './styles';

export interface SupportFormFields {
  email: string;
  subject: string;
  name: string;
  message: string;
}

function Support() {
  const intl = useIntl();
  const { locale } = intl;
  const [sendSupportMessage, { loading, data, error }] = useSendSupportMessageMutation();
  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    reset,
  } = useForm<SupportFormFields>();
  const { errors } = formState;
  const feedback = useFeedback();
  const onSubmit: SubmitHandler<SupportFormFields> = useCallback(async (formData) => {
    const {
      email,
      subject,
      name,
      message,
    } = formData;
    await sendSupportMessage({
      variables: {
        fields: {
          email,
          language: locale,
          message,
          name,
          subject,
        },
      },
    });
  }, [locale, sendSupportMessage]);

  useEffect(() => {
    if (data) {
      clearErrors();
      reset();
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="support_sent_success" />),
        severity: 'success',
      });
    }
  }, [data, clearErrors, reset, feedback]);

  return (
    <LayoutWithBackground>
      <CenteredContainer>
        <FormContainer elevation={8}>
          <FullWidthForm onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
              <Grid item display="flex" justifyContent="center" width="100%">
                <h1>
                  <FormattedMessage id="support_title" />
                </h1>
              </Grid>
              <Grid display="flex" justifyContent="center" width="60%" flexDirection="column">
                <TextField
                  variant="standard"
                  label={<FormattedMessage id="support_name" />}
                  fullWidth
                  {...register('name')}
                />
                <FormErrors error={errors.name} />
              </Grid>
              <Grid display="flex" justifyContent="center" width="60%" flexDirection="column">
                <TextField
                  variant="standard"
                  label={<FormattedMessage id="support_email" />}
                  {...register('email', { required: true })}
                  fullWidth
                />
                <FormErrors error={errors.email} />
              </Grid>
              <Grid display="flex" justifyContent="center" width="60%" flexDirection="column">
                <TextField
                  variant="standard"
                  label={<FormattedMessage id="support_subject" />}
                  {...register('subject', { required: true })}
                  fullWidth
                />
                <FormErrors error={errors.subject} />
              </Grid>
              <Grid width="60%" display="flex" justifyContent="center" flexDirection="column">
                <TextField
                  variant="standard"
                  label={<FormattedMessage id="support_message" />}
                  multiline
                  minRows={5}
                  fullWidth
                  {...register('message', { required: true })}
                />
                <FormErrors error={errors.message} />
              </Grid>
              <Grid width="60%" display="flex" justifyContent="center" flexDirection="column">
                <LoadingButton
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="support_send" />
                </LoadingButton>
                {error && (
                  <FormErrors
                    error={{
                      type: 'error',
                      message: intl.formatMessage({ id: 'support_sent_error' }),
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </FullWidthForm>
        </FormContainer>
      </CenteredContainer>
    </LayoutWithBackground>
  );
}

export default Support;
