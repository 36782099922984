import { ShoppingCart } from '@mui/icons-material';
import {
  CardContent,
  CardHeader,
  Dialog,
} from '@mui/material';
import SuspenseLoader from 'components/Loading/SuspenseLoader';
import useCart from 'providers/CartProvider/useCart';
import { EventContext } from 'providers/EventProvider/EventContext';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { DangerButton, OutlinedSecondaryButton } from 'styles/styles';

import Communication from './components/Communication/Communication';
import CountdownTimer from './components/CountdownTimer/CountdownTimer';
import Impact from './components/Impact/Impact';
import Payment from './components/Payment/Payment';
import Summary from './components/Summary/Summary';
import {
  Card,
  CardTitle,
  CenteredCardActions,
  Container,
  Step,
  StepConnector,
  StepIconContainer,
  Stepper,
  StepText,
} from './styles';

enum CartSteps {
  CartSummary = 'summary',
  CartImpact = 'impact',
  CartCommunication = 'communication',
  CartPayment = 'payment',
}

function Cart() {
  const {
    createCheckoutSession,
    cancelTickets,
    reserveTickets,
    setObnlId,
    stripeCheckoutReturnUrl,
  } = useCart();
  const { clearOrder, order } = useContext(TicketingContext);
  const event = useContext(EventContext);

  const [currentStep, setCurrentStep] = useState(CartSteps.CartSummary);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBackButtonEvent = useCallback((e: PopStateEvent) => {
    e.preventDefault();
    setDialogOpen(true);
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, document.title, location.href);
  }, []);

  const handleBeforeUnloadEvent = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ''; // Chrome requires returnValue to be set
    setDialogOpen(true);
  }, []);

  useEffect(() => {
    // Push a "dummy" entry onto the history stack before we start listening for 'popstate' events
    if (currentStep === CartSteps.CartImpact || currentStep === CartSteps.CartPayment) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, document.title, location.href);

      window.addEventListener('popstate', handleBackButtonEvent);
      window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    };
  }, [currentStep, handleBackButtonEvent, handleBeforeUnloadEvent]);

  const handleCancelDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleAcceptDialog = useCallback(async () => {
    clearOrder();
    if (!order?.isFreemium) {
      await cancelTickets();
    }
    setDialogOpen(false);
    setCurrentStep(CartSteps.CartSummary);
  }, [cancelTickets, clearOrder, order?.isFreemium]);

  const finalSteps = useMemo(() => (
    [
      CartSteps.CartSummary,
      event.allowObnlChoice && CartSteps.CartImpact,
      !order?.isFreemium && CartSteps.CartCommunication,
      CartSteps.CartPayment,
    ].filter(Boolean) as CartSteps[]
  ), [event.allowObnlChoice, order?.isFreemium]);

  const toNextStep = useCallback(async () => {
    const nextStep = finalSteps[finalSteps.indexOf(currentStep) + 1];
    if (nextStep) {
      if (nextStep === CartSteps.CartPayment) {
        await createCheckoutSession();
      }
      setCurrentStep(nextStep);
    }
  }, [createCheckoutSession, currentStep, finalSteps]);

  const toPreviousStep = useCallback(() => {
    const previousStep = finalSteps[finalSteps.indexOf(currentStep) - 1];
    if (previousStep) {
      if (previousStep === CartSteps.CartSummary) {
        setDialogOpen(true);
      } else {
        setCurrentStep(previousStep);
      }
    }
  }, [currentStep, finalSteps]);

  if (!order) return null;

  return (
    <>
      {currentStep !== CartSteps.CartSummary && (
        <CountdownTimer />
      )}
      <Container>
        <Stepper $hasTimer={currentStep !== CartSteps.CartSummary}>
          {finalSteps.map((step) => (
            <Fragment key={step}>
              <Step key={step}>
                {step === currentStep && (
                  <StepIconContainer>
                    <ShoppingCart />
                  </StepIconContainer>
                )}
                <StepText $active={step === currentStep}>
                  <FormattedMessage id={`cart_${step}`} />
                </StepText>
              </Step>
              {step !== CartSteps.CartPayment && <StepConnector />}
            </Fragment>
          ))}
        </Stepper>
        <Card $lessPadding={currentStep === CartSteps.CartPayment}>
          <CardTitle>
            <FormattedMessage id={`cart_${currentStep}`} />
          </CardTitle>
          {currentStep === CartSteps.CartSummary && (
            <SuspenseLoader>
              <Summary
                onNext={async () => {
                  await reserveTickets(order);
                  if (!event.allowObnlChoice) {
                    setObnlId(event.obnls?.[0]);
                  }
                  await toNextStep();
                }}
              />
            </SuspenseLoader>
          )}
          {currentStep === CartSteps.CartImpact && (
            <Impact
              onBack={toPreviousStep}
              onNext={toNextStep}
            />
          )}
          {currentStep === CartSteps.CartCommunication && (
            <Communication
              onBack={toPreviousStep}
              onNext={toNextStep}
            />
          )}
          {currentStep === CartSteps.CartPayment && (
            <Payment
              onBack={toPreviousStep}
              onComplete={() => {
                window.removeEventListener('popstate', handleBackButtonEvent);
                window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
                if (stripeCheckoutReturnUrl) window.location.href = stripeCheckoutReturnUrl;
              }}
            />
          )}
        </Card>
      </Container>
      <Dialog open={dialogOpen}>
        <CardHeader title={<FormattedMessage id="cancel_order_title" />} />
        <CardContent>
          <FormattedMessage id="cancel_order_body" />
        </CardContent>
        <CenteredCardActions>
          <OutlinedSecondaryButton onClick={handleCancelDialog}>
            <FormattedMessage id="general_no" />
          </OutlinedSecondaryButton>
          <DangerButton onClick={handleAcceptDialog}>
            <FormattedMessage id="general_yes" />
          </DangerButton>
        </CenteredCardActions>
      </Dialog>
    </>
  );
}

export default Cart;
