import Settings from './Settings';

const settings: Settings = {
  anyware: {
    licenseKey: 'novom:key',
    rootTopic: 'movon/cblanchette',
  },
  cloudinary: {
    cloudName: 'novom-networks',
    projectName: 'MovOnVIP',
  },
  devIp: 'localhost',
  graphql: {
    url: 'http://localhost:3000/local/graphql',
  },
  logLevel: 'DEBUG',
  stripe: {
    publishableKey: 'pk_test_51NOLm9IzTi1pf1P8jwvOez2YNv0bR2suq3U0KqQpCdo5VVhfDfXblccXbYhIXf2dNd3bekaIH6fQ47plDgJPGOMh003YXeSdKm',
  },
  support: {
    en: 'https://support.movon.vip/en/hc/3673856758',
    es: 'https://support.movon.vip/es/hc/3673856758',
    fr: 'https://support.movon.vip/fr/hc/3673856758',
  },
};

export default settings;
