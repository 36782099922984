const termsEn: { [name: string]: string } = {
  terms_conditions: 'Terms & Conditions',
  terms_conditions_1_content: 'Welcome to the MovOn.VIP web application (the "Site"). The Site is operated by Novom Interactive (the "Operator"). By accessing and using the Site, you agree to be bound by these Terms of Use (the "Terms"). If you do not agree to these Terms, you must not access or use the Site.',
  terms_conditions_1_title: '1. Introduction',
  terms_conditions_2_content: 'MovOn.VIP is committed to complying with Quebec\'s Bill 25 and the General Data Protection Regulation (GDPR) with respect to the collection, use and disclosure of your personal information. For more information on our data protection practices, please see our Privacy Policy.',
  terms_conditions_2_title: '2. Compliance with Law 25 and the GDPR',
  terms_conditions_3_content: 'MovOn.VIP will never sell your personal information to third parties. We may share your personal information with third-party service providers who help us operate the Site, but we will do so only to the extent necessary to provide those services.',
  terms_conditions_3_title: '3. No sale of data to third parties',
  terms_conditions_4_content: 'All content on the Site, including text, images, video and software, is protected by copyright and other intellectual property rights of the Operator or its licensors. You may not use the content of the Site without the prior written consent of the Operator.',
  terms_conditions_4_title: '4. Intellectual property rights',
  terms_conditions_5_content: 'MovOn.VIP grants you a non-exclusive, non-transferable license to use the Site. You are not authorized to use the Site for illegal or harmful purposes, or to violate the rights of the Operator or third parties.',
  terms_conditions_5_title: '5. Authorization granted to users',
  terms_conditions_6_content: 'MovOn.VIP shall not be liable for any direct, indirect, incidental, special or consequential damages, including loss of profits, goodwill or data, arising out of the use or inability to use the Site, even if MovOn.VIP has been advised of the possibility of such damages.',
  terms_conditions_6_title: '6. Limitation of liability',
  terms_conditions_7_content: 'These Terms and Conditions shall be governed by and construed in accordance with the laws of the Province of Quebec. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the competent courts of the Province of Quebec.',
  terms_conditions_7_title: '7. Applicable law and jurisdiction',
  terms_conditions_8_content: 'MovOn.VIP may modify these Terms at any time by posting the modified Terms on the Site. The modified Terms will come into effect as soon as they are published. You are responsible for regularly reviewing the Terms to ensure that you are aware of any changes.',
  terms_conditions_8_title: '8. Changes to the Terms',
  terms_conditions_9_content: 'The French version of these Terms shall prevail over any other translation.',
  terms_conditions_9_title: '9. Language of translation',
  terms_conditions_10_content: 'If you have any questions regarding these Terms, please contact MovOn.VIP at <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contact',
  terms_conditions_copyright: '© {year} MovOn.VIP',
};

export default termsEn;
