import { FormControlLabel, formControlLabelClasses } from '@mui/material';
import styled, { css } from 'styled-components';
import { ActionButton, hexToRgbString } from 'styles/styles';

export const CommunicationDescriptionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1;
`;

export const CommunicationChoices = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

interface CommunicationChoiceProps {
  $selected: boolean
}

export const CommunicationChoice = styled(ActionButton)<CommunicationChoiceProps>` 
  ${({ $selected }) => $selected && css`
    background-color: ${({ theme }) => theme.palette.custom.lightSecondary.main};
    outline: 4px solid rgba(${({ theme }) => hexToRgbString(theme.palette.custom.secondaryAccent.main)} / 40%);
    color: ${({ theme }) => theme.palette.custom.lightSecondary.contrastText};
    border: 0;
    margin: 0;
  `}
  padding: 2rem;
  width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CommunicationChoiceTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
`;

export const OptInCheckBoxContainer = styled.div`
  align-self: center;
  padding: 0 32px;
`;

export const OptInLabel = styled(FormControlLabel)`
  margin: 0;

  & .${formControlLabelClasses.label} {
    font-size: ${({ theme }) => theme.fontSizes.normal};
  }
`;
