import { styled } from 'styled-components';

export const SmallText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
  font-size: ${({ theme }) => theme.fontSizes.normal};
`;

export const TicketNumberContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.custom.background.contrastText};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;
