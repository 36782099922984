import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import { GetEventItem } from './graphql/event/types';
import { GetWaitingLineItem } from './graphql/waitingLines/types';
import { Languages } from './providers/Language/LanguageContext';

export function createCanvasFromImage(imageSrc: string): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    };
    img.onerror = reject;
    img.src = imageSrc;
  });
}

function wrapText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
) {
  const words = text.split(' ');
  const ellipsis = '...';

  const finalLine = words.reduce((line: string, word: string, n: number) => {
    const concat = `${line + word} `;
    const metrics = ctx.measureText(concat);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      // Recursively call this function to wrap the line
      wrapText(ctx, line.trim(), x, y, maxWidth, lineHeight);
      // eslint-disable-next-line no-param-reassign
      y += lineHeight;
      return `${word} `;
    }
    return concat;
  }, '').trim();

  // Check if last line fits within the maxWidth, if not add ellipsis
  const metrics = ctx.measureText(finalLine);
  if (metrics.width > maxWidth) {
    const shortenedLine = Array.from(finalLine).reduce((acc, char) => {
      const str = acc + char;
      if (ctx.measureText(str + ellipsis).width <= maxWidth) {
        return str;
      }
      return acc;
    }, '');

    ctx.fillText(shortenedLine.trim() + ellipsis, x, y);
  } else {
    ctx.fillText(finalLine, x, y);
  }
}

export function drawQRCode(
  canvas: HTMLCanvasElement,
  svgData: string,
  waitingLine: NonNullable<GetWaitingLineItem>,
  event: NonNullable<GetEventItem>,
  language: Languages,
  quantity: number,
  intl: IntlShape,
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const eventLanguage = event.languages[0] as Languages;

    const eventName = event.name[language] || event.name[eventLanguage] || '';
    const waitingLineName = waitingLine.name[language] || waitingLine.name[eventLanguage] || '';

    const img = new Image();
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      const qrSize = Math.min(canvas.width, canvas.height) * 0.37;
      const qrX = (canvas.width - qrSize) / 2 + 0.5;
      const qrY = (canvas.height - qrSize) / 2 + 78;

      ctx.fillStyle = 'white';
      ctx.fillRect(qrX, qrY, qrSize, qrSize);
      ctx.drawImage(img, qrX, qrY, qrSize, qrSize);

      const start = DateTime.fromISO(event.startTime).setZone(event.timezone);
      const end = DateTime.fromISO(event.endTime).setZone(event.timezone);

      ctx.font = '500 16.5px Roboto, sans-serif';
      ctx.fillStyle = 'white';

      wrapText(ctx, `${eventName} | ${intl.formatMessage({ id: 'passes' }, { quantity })}`, qrX - 113, qrY - 280, 375, 20);
      ctx.fillText(start.setLocale(language).toFormat('DDD t'), qrX - 113, qrY - 150, 150);
      ctx.fillText(end.setLocale(language).toFormat('DDD t'), qrX + 101, qrY - 150, 150);
      wrapText(ctx, event.location, qrX - 113, qrY - 94, 375, 20);
      wrapText(ctx, event.timezone, qrX - 113, qrY - 38, 375, 20);

      ctx.font = '36px Roboto, sans-serif';
      wrapText(ctx, waitingLineName, qrX - 113, qrY - 220, 375, 32);

      ctx.font = '14px Roboto, sans-serif';
      ctx.fillText(intl.formatMessage({ id: 'event_starts' }), qrX - 113, qrY - 172);
      ctx.fillText(intl.formatMessage({ id: 'event_ends' }), qrX + 101, qrY - 172);
      ctx.fillText(intl.formatMessage({ id: 'event_location' }), qrX - 113, qrY - 116);
      ctx.fillText(intl.formatMessage({ id: 'event_timezone' }), qrX - 113, qrY - 60);

      resolve();
    };
    img.onerror = (e) => reject(e);
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  });
}
