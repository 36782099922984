import styled, { css } from 'styled-components';
import { ActionButton, hexToRgbString } from 'styles/styles';

export const ImpactDescriptionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1;
`;

export const ImpactDescriptionAccent = styled(ImpactDescriptionText)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ImpactChoices = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

interface ImpactChoiceProps {
  $selected: boolean
}

export const ImpactChoice = styled(ActionButton)<ImpactChoiceProps>` 
  ${({ $selected }) => $selected && css`
    background-color: ${({ theme }) => theme.palette.custom.lightSecondary.main};
    outline: 4px solid rgba(${({ theme }) => hexToRgbString(theme.palette.custom.secondaryAccent.main)} / 40%);
    color: ${({ theme }) => theme.palette.custom.lightSecondary.contrastText};
    border: 0;
    margin: 0;
  `}
`;

export const ChoiceDescriptionContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  outline: 2px solid rgba(${({ theme }) => hexToRgbString(theme.palette.custom.secondaryAccent.main)} / 40%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const ChoiceTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const ChoiceTitleDot = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  opacity: 0.6;
`;

export const ChoiceName = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  text-align: center;
`;

export const ChoiceObjective = styled(ChoiceName)`
  font-weight: bold;
  text-transform: uppercase;
`;

export const ChoiceDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.palette.custom.gray};
  text-align: center;
`;
