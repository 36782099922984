import styled from 'styled-components';
import { BaseButton } from 'styles/styles';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.custom.gray};
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  padding: 4px 8px;
`;

interface CircleStatusProps {
  $background: string;
}

export const CircleStatus = styled.div<CircleStatusProps>`
  width: 13px;
  height: 13px;
  border-radius: 6.5px;
  background: ${({ $background }) => $background};
`;

export const StatusText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 0.25rem 0;
`;

export const IconButton = styled(BaseButton)`
  color: white;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  ${({ disabled }) => disabled && 'visibility: hidden;'}
`;
