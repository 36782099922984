import { Client } from '@novominteractive/ase-react-apollo';
import settings from 'lib/settings';

export interface AseAuthData {
  token: string
}

const client = new Client({
  connectionHandlers: {
    onDisconnect: () => console.log('Disconnect'),
    onError: (err: any) => console.error('err', err),
    onInterrupt: (err: any) => console.error('interrupt', err),
    onResume: (code: any, sessionPresent: any) => console.log(`Resume: ${code} ${sessionPresent}`),
  },
  iotConnect: {
    authorizerName: 'AnywareAuthorizer-ci',
  },
  licenseKey: settings.anyware.licenseKey,
  rootTopic: settings.anyware.rootTopic,
});

export default client;
