import { PropsWithChildren } from 'react';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Container, Content } from './styles';

interface LayoutProps extends PropsWithChildren {
  className?: string
  title?: React.ReactNode
}

function Layout(props: LayoutProps) {
  const { className, children, title } = props;

  return (
    <Container className={className}>
      <Header title={title} />
      <Content>
        {children}
        <Footer />
      </Content>
    </Container>
  );
}

export default Layout;
