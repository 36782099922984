import styled from 'styled-components';

export const AlertIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const AlertIconContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;
