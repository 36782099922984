/* cSpell:language es, en */
import policiesEs from './LegalAgreements/policiesEs';
import termsEs from './LegalAgreements/TermsEs';

const messages: I18NMessages = {
  about_movon: 'Acerca de MovOn.VIP',

  alert_something_wrong: '¡Algo salió mal!',
  alert_waiting_line_not_active: 'La cola está actualmente inactiva. Por favor, inténtelo de nuevo más tarde.',
  alert_waiting_line_not_enough_tickets: 'No hay suficientes entradas disponibles. Gracias por su comprensión.',
  alert_waiting_line_reservation_expired: 'El tiempo reservado para comprar tus pases ha expirado. Inténtalo de nuevo.',
  alert_return: 'Volver a los pases',

  cancel_order_title: '¿Estás seguro de que quieres cancelar tu pedido?',
  cancel_order_body: 'Salir ahora cancelará su pedido y perderá su posición en la cola. ¿Volver atrás?',

  cart_choose_for_me: 'Elige por mí',
  cart_choose: 'Quiero elegir',
  cart_countdown: 'Tiempo restante: {time}',
  cart_email: 'Correo',
  cart_sms: 'SMS',
  cart_summary: 'Resumen',
  cart_summary_movon: 'MovOn.VIP se compromete a pagar el 33% de los ingresos a {obnl}',
  cart_impact: 'Impacto',
  cart_impact_description: 'Por cada pase MovOn.VIP vendido,',
  cart_impact_follow: '33% va a una causa benéfica',
  cart_communication: 'Contacto',
  cart_communication_description: 'Elige tu método de comunicación preferido para recibir actualizaciones sobre el estado de tus pases.',
  cart_communication_opt_in_notifications: 'Recibir notificaciones del organizador',
  cart_payment: 'Pago',
  cart_remove: 'Retirar',
  cart_subtotal: 'Total parcial',
  cart_tax: 'Impuestos',

  event_starts: 'Válido a partir del',
  event_ends: 'Hasta el',
  event_location: 'Ubicación del evento',
  event_no_waiting_line_available: 'No se ha creado ninguna cola para este evento.',
  event_timezone: 'Zona horaria',

  get_vip_access: 'Obtener un acceso VIP',

  general_back: 'Volver',
  general_cancel: 'Cancelar',
  general_close: 'Cerrar',
  generic_form_error_required: 'Este campo es obligatorio',
  general_next: 'Próximo',
  general_no: 'No',
  general_select: 'Elegir',
  general_yes: 'Sí',

  movon_title: 'MovOn.VIP',

  obnl_cities: 'Ciudades y comunidades sostenibles',
  obnl_climate: 'Acción por el clima',
  obnl_consumption: 'Producción y consumo responsables',
  obnl_education: 'Educación de calidad',
  obnl_energy: 'Energía asequible y no contaminante',
  obnl_gender: 'Igualdad de género',
  obnl_health: 'Salud y bienestar',
  obnl_hunger: 'Hambre cero',
  obnl_industry: 'Industria, innovación e infraestructura',
  obnl_inequalities: 'Redducción de las desigualdades',
  obnl_life_land: 'Vida de ecosistemas terrestres',
  obnl_partnerships: 'Alianzas para lograr los objetivos',
  obnl_peace: 'Paz, justicia e instituciones sólidas',
  obnl_poverty: 'Fin de la pobreza',
  obnl_water: 'Agua limpia y saneamiento',
  obnl_water_life: 'Viia submarina',
  obnl_work: 'Trabajo decente y crecimiento económico',

  onboarding_cause: 'Apoye una causa que esté cerca de su corazón.',
  onboarding_obtain: 'Obtén acceso VIP',

  pass_congratulations: '¡Felicidades ',
  pass_download_pass: 'o descarga tu pase',
  pass_owner_gold: 'Pase VIP Gold de {name}',
  pass_owner_silver: 'Pase VIP Silver de {name}',
  pass_owner_silver_no_name: 'Su Pass VIP Silver',
  pass_owner_gold_no_name: 'Su Pass VIP Gold',
  pass_gold_vip: 'Ahora eres VIP Gold',
  pass_silver_vip: 'Ahora eres VIP Silver',
  pass_help: '¿Necesitas ayuda?',
  pass_line_status: 'Estado de la línea de espera',
  pass_resend_confirmation: 'Reenviar la confirmación',
  pass_resend_error: 'Ocurrió un error al reenviar su confirmación.',
  pass_resend_success: 'Su confirmación ha sido reenviado con éxito.',
  pass_sent_to: 'Su pase ha sido enviado a {email}',
  pass_show_pass: 'Muestre su pase al cajero para saltarse la cola',
  pass_what_next: '¿Qué sigue?',

  passes: '{quantity} pase{quantity, plural, one {} other {s}}',

  pdq_avoid_waiting_line: '¡Evita la cola!',
  pdq_in_a_hurry: '¿Apurado?',

  placeholder_email_address: 'Correo electrónico',
  placeholder_sms_number: 'Número de teléfono',

  payment_information: 'Información de pago',

  reload: 'Recargar',

  support_cause: 'Apoye una causa que esté cerca de su corazón.',

  support_title: 'Apoyo',
  support_name: 'Nombre (opcional)',
  support_email: 'Correo',
  support_subject: 'Asunto',
  support_message: 'Mensaje',
  support_send: 'Enviar',
  support_sent_error: 'Ocurrió un error al enviar su mensaje. Por favor, inténtelo de nuevo más tarde o envíe un correo electrónico a help@movon.vip.',
  support_sent_success: 'Su mensaje ha sido enviado con éxito. Nos pondremos en contacto con usted lo antes posible.',

  tickets_enter: 'Ingresar',
  tickets_checkout: 'Pagar',
  tickets_gold_title: 'Saltarse la cola',
  tickets_group_size: 'Límite por transacción',
  tickets_group_size_value: '{limit} pase{limit, plural, one {} other{s}}',
  tickets_quantity: 'Cantidad',
  tickets_quantity_available: '{quantity} disponible{quantity, plural, one {} other {s}}',
  tickets_quantity_sold_out: 'Todos los pases están agotados',
  tickets_silver_title: 'Guarda tu lugar',

  use_camera_app_qr: 'Utiliza tu aplicación de <tag>cámara</tag> para escanear el código QR.',

  waiting_line_called: '¡Es tu turno!',
  waiting_line_claim_free_error: 'Se produjo un error, inténtelo nuevamente más tarde.',
  waiting_line_claim_free_invalid_email: 'Su dirección de correo electrónico debe contener al menos una @.',
  waiting_line_register: '¡Registro!',
  waiting_line_register_use_email: 'Utilice una dirección de correo electrónico',
  waiting_line_register_use_sms: 'Utilice un número de teléfono',
  waiting_line_status_info_title: 'Información de estado',
  waiting_line_waiting: 'Recibirás un mensaje al ingresar',
  waiting_line_waiting_estimated_disclaimer: '* tiempo estimado',
  waiting_line_waiting_estimated_wait: 'Tiempo de espera estimado: {minutes} min',
  waiting_line_waiting_price: 'por solo <Price>{price}</Price>',
  waiting_line_waiting_saved_time: 'Ahorra {minutes} minutos*',
  waiting_line_waiting_ticket_number: 'Tu número de pase',
  waiting_line_waiting_upgrade: 'Actualizar a VIP',
  waiting_line_waiting_welcome: 'Bienvenido,',

  ...policiesEs,
  ...termsEs,
};

export default messages;
