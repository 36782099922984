export type Currency = {
  name: string;
  symbol: string;
  symbolNative: string;
  decimalDigits: number;
  rounding: number;
  code: string;
  namePlural: string;
};

export const currencies: Currency[] = [{
  code: 'USD',
  decimalDigits: 2,
  name: 'US Dollar',
  namePlural: 'US dollars',
  rounding: 0,
  symbol: '$',
  symbolNative: '$',
}, {
  code: 'CAD',
  decimalDigits: 2,
  name: 'Canadian Dollar',
  namePlural: 'Canadian dollars',
  rounding: 0,
  symbol: '$',
  symbolNative: '$',
}, {
  code: 'EUR',
  decimalDigits: 2,
  name: 'Euro',
  namePlural: 'euros',
  rounding: 0,
  symbol: '€',
  symbolNative: '€',
}];

export const currenciesMap = currencies.reduce<Record<string, Currency>>((acc, currency) => ({
  ...acc,
  [currency.code]: currency,
}), {});
