import { dialogClasses } from '@mui/material';
import styled from 'styled-components';
import { StyledDialog } from 'styles/styles';

export const FooterContainer = styled.div`
  width: 100%;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  @media print {
    display: none;
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

export const FooterText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: white;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SeparatingDot = styled.div`
  display: flex;
  flex-basis: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: white;
  margin: 0 10px;
`;

export const BigStyledDialog = styled(StyledDialog)`
  & .${dialogClasses.paper} {
    max-width: 900px;
    background: ${({ theme }) => theme.palette.background.default};
  }
`;
