import { Typography } from '@mui/material';
import { styled } from 'styled-components';

export const WhatsNextTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  
  @media print {
    color: black;
  }
`;

export const WhatsNextText = styled(Typography)`
  font-size: 12px;
  color: white;
  line-height: 1.2;

  @media print {
    color: black;
  }
`;
