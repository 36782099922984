import { createContext } from 'react';

export enum Languages {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
}
export interface ILanguageContext {
  language: Languages
  setLanguage: (newLanguage: Languages) => void
}

export const DefaultLanguageContext: ILanguageContext = {
  language: Languages.EN,
  setLanguage: () => {},
};
const LanguageContext = createContext(DefaultLanguageContext);
export default LanguageContext;
