import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import stripe from 'lib/stripe';
import useCart from 'providers/CartProvider/useCart';
import { useCallback, useRef } from 'react';

interface CheckoutFormProps {
  onComplete: () => void;
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { onComplete } = props;

  const { stripeCheckoutSessionClientSecret } = useCart();

  const stableOnComplete = useRef(onComplete);
  stableOnComplete.current = onComplete;

  const handleOnComplete = useCallback(() => {
    stableOnComplete.current();
  }, []);

  return (
    <EmbeddedCheckoutProvider
      key={stripeCheckoutSessionClientSecret}
      stripe={stripe}
      options={{
        clientSecret: stripeCheckoutSessionClientSecret,
        onComplete: handleOnComplete,
      }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}
