import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { NullableText } from 'lib/graphql/graphql';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { EventContext } from 'providers/EventProvider/EventContext';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton, StyledDialog } from 'styles/styles';

import { Description, Title } from './styles';

interface InformationModalProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  message?: NullableText
}

function InformationModal(props: InformationModalProps) {
  const { modalOpen, setModalOpen, message } = props;
  const { language } = useContext(LanguageContext);
  const event = useContext(EventContext);

  return (
    <StyledDialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Title>
          <FormattedMessage id="waiting_line_status_info_title" />
        </Title>
      </DialogTitle>
      <DialogContent>
        <Description>
          {message && (message[language]
            || message[event.languages[0] as Languages])}
        </Description>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={() => setModalOpen(false)}>
          <FormattedMessage id="general_close" />
        </PrimaryButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default InformationModal;
