import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.75rem 1rem 0.5rem;
  gap: 0.5rem;

  @media print {
    padding: 24px 0 16px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
  text-align: center;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.bigger};
`;

interface LogoProps {
  $hidden?: boolean;
}

export const Logo = styled.img<LogoProps>`
  height: 4rem;
  object-fit: contain;
  cursor: pointer;
  ${({ $hidden }) => $hidden && 'visibility: collapse;'}
`;
