import ErrorPage from 'components/ErrorPage/ErrorPage';
import NumberInput from 'components/NumberInput/NumberInput';
import { currencies } from 'lib/currencies';
import getMaxAmountLeft from 'lib/getMaxAmountLeft';
import { useGetWaitingLineLazyQuery } from 'lib/graphql/graphql';
import { GetWaitingLineItem } from 'lib/graphql/waitingLines/types';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { CardButtons, CardButtonSpacer } from 'modules/Cart/styles';
import { EventContext } from 'providers/EventProvider/EventContext';
import { Order, TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedSecondaryButton, PrimaryButton, PrimaryLinkButton } from 'styles/styles';

import QuantityInfos from '../QuantityInfos/QuantityInfos';
import {
  MoneyContainer,
  MoneyPrice,
  MoneySection,
  MoneyTitle,
  Separator,
  SummaryItem,
  SummaryItemContent,
  SummaryItemInfos,
  SummaryItemPrice,
  SummaryItems,
  SummaryItemTitle,
} from './styles';

interface SummaryProps {
  onNext: () => void
}

function Summary(props: SummaryProps) {
  const { onNext } = props;
  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);

  const [waitingLine, setWaitingLine] = useState<GetWaitingLineItem | undefined | null>(undefined);

  const [getEventWaitingLine, { error }] = useGetWaitingLineLazyQuery();

  const {
    clearOrder,
    updateOrder,
    order,
    removeItem,
  } = useContext(TicketingContext);
  const { amount, waitingLineId } = order || {} as Order;

  const getWaitingLine = useCallback(async () => {
    const { data } = await getEventWaitingLine({
      variables: { id: waitingLineId },
      fetchPolicy: 'network-only',
    });
    return data?.getWaitingLine;
  }, [getEventWaitingLine, waitingLineId]);

  useEffect(() => {
    async function fetchWaitingLine() {
      if (!waitingLineId) return;
      const newWaitingLine = await getWaitingLine();
      setWaitingLine(newWaitingLine);
    }

    setInterval(() => {
      fetchWaitingLine();
    }, 30000);

    fetchWaitingLine();
  }, [event.id, getEventWaitingLine, getWaitingLine, waitingLineId]);

  const currencySymbol = useMemo(() => {
    if (!waitingLine?.currency) return '$';
    return currencies.find((c) => c.code === waitingLine.currency.toUpperCase())?.symbol;
  }, [waitingLine]);

  const maxAmount = useMemo(() => {
    if (waitingLine?.limitedQuantity) {
      return Math.min(waitingLine.maxGroupSize, waitingLine.quantity as number);
    }
    if (waitingLine?.maxGroupSize) {
      return waitingLine.maxGroupSize;
    }
    return undefined;
  }, [waitingLine]);

  if (!waitingLine) return null;

  const subTotal = (waitingLine.price || 0) * amount;

  const amountLeft = waitingLine.limitedQuantity ? (
    (waitingLine.quantity as number) - waitingLine.usedTickets)
    : undefined;

  if (error) return <ErrorPage title={error.message} />;

  const maxAmountLeft = getMaxAmountLeft(maxAmount, amountLeft);

  const eventLanguage = event.languages[0] as Languages;

  return (
    <>
      <SummaryItems>
        <SummaryItem>
          <SummaryItemContent>
            <SummaryItemInfos>
              <SummaryItemTitle>
                {waitingLine.name[language] || waitingLine.name[eventLanguage]}
              </SummaryItemTitle>
              <SummaryItemPrice>
                {`${waitingLine.price.toFixed(2)}${currencySymbol} ${waitingLine.currency.toUpperCase()}`}
              </SummaryItemPrice>
            </SummaryItemInfos>
            <PrimaryLinkButton onClick={() => removeItem(waitingLineId)}>
              <FormattedMessage id="cart_remove" />
            </PrimaryLinkButton>
          </SummaryItemContent>
          {!waitingLine.isFreemium && (
            <>
              <QuantityInfos
                available={amountLeft}
                maxAmount={maxAmount}
              />
              <NumberInput
                value={amount}
                onChange={(value) => updateOrder({ amount: value })}
                max={maxAmountLeft}
                min={1}
                disabled={amountLeft !== undefined && amountLeft <= 0}
              />
            </>
          )}
        </SummaryItem>
      </SummaryItems>
      <Separator />
      {amountLeft !== 0 && (
        <MoneyContainer>
          <MoneySection>
            <MoneyTitle>
              <FormattedMessage id="cart_subtotal" />
            </MoneyTitle>
            <MoneyPrice>
              {`${subTotal.toFixed(2)}${currencySymbol} ${waitingLine.currency.toUpperCase()}`}
            </MoneyPrice>
          </MoneySection>
        </MoneyContainer>
      )}
      <CardButtonSpacer />
      <CardButtons>
        <OutlinedSecondaryButton onClick={clearOrder}>
          <FormattedMessage id="general_back" />
        </OutlinedSecondaryButton>
        <PrimaryButton
          disabled={amount <= 0 || amountLeft === 0}
          onClick={onNext}
        >
          <FormattedMessage id="general_next" />
        </PrimaryButton>
      </CardButtons>
    </>
  );
}

export default Summary;
