import { Component, ErrorInfo } from 'react';

import ErrorPage from './ErrorPage';

interface ErrorBoundaryProps {
  children: JSX.Element
  transformMessage?: (error: Error) => React.ReactNode
  onCatch?: (error: Error, errorInfo: ErrorInfo) => void
}

interface ErrorBoundaryState {
  error: Error | undefined
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { onCatch } = this.props;
    onCatch?.(error, errorInfo);
  }

  render() {
    const { children, transformMessage } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorPage
          title={transformMessage ? transformMessage(error) : error.message}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
