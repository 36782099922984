import goldIcon from 'assets/images/gold-ticket-v2.png';
import silverIcon from 'assets/images/Silver-ticket-v2.png';
import NumberInput from 'components/NumberInput/NumberInput';
import { currencies } from 'lib/currencies';
import getMaxAmountLeft from 'lib/getMaxAmountLeft';
import { GetAllEventWaitingLinesQuery, WaitingLineStatus, WaitingLineType } from 'lib/graphql/graphql';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import QuantityInfos from 'modules/Cart/components/QuantityInfos/QuantityInfos';
import { EventContext } from 'providers/EventProvider/EventContext';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import { useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from 'styles/styles';
import { useLocation } from 'wouter';

import InformationModal from './components/InformationModal/InformationModal';
import StatusBar from './components/StatusBar/StatusBar';
import {
  Price,
  Spacer,
  TickerHeader,
  TicketActions,
  TicketContainer,
  TicketContent,
  TicketContentText,
  TicketDescription,
  TicketIcon,
  TicketTitle,
} from './styles';

type WaitingLine = GetAllEventWaitingLinesQuery['getAllEventWaitingLines']['waitingLines'][number];

interface TicketProps {
  waitingLine: WaitingLine
}

function Ticket(props: TicketProps) {
  const { waitingLine } = props;
  const {
    id,
    isFreemium,
    limitedQuantity,
    quantity,
    status,
    message,
    price,
    currency,
    maxGroupSize,
    waitingLineType,
    name,
    usedTickets = 0,
    productDescription,
  } = waitingLine;

  const [, setLocation] = useLocation();

  const { checkout } = useContext(TicketingContext);

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);

  const [checkoutQuantity, setCheckoutQuantity] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const maxAmount = useMemo(() => {
    if (limitedQuantity) {
      return Math.min(maxGroupSize, quantity as number);
    }
    if (maxGroupSize) {
      return maxGroupSize;
    }
    return Infinity;
  }, [maxGroupSize, limitedQuantity, quantity]);

  const amountLeft = useMemo(() => (limitedQuantity
    ? quantity! - usedTickets : undefined), [limitedQuantity, quantity, usedTickets]);

  const maxAmountLeft = getMaxAmountLeft(maxAmount, amountLeft);

  const symbol = useMemo(() => (
    currencies.find((c) => c.code === currency.toUpperCase())?.symbolNative
  ), [currency]);

  const disabled = status === WaitingLineStatus.Inactive || (limitedQuantity && amountLeft! <= 0);

  const eventLanguage = event.languages[0] as Languages;

  const productDescriptionText = useMemo(() => {
    if (productDescription) {
      return productDescription[language] || productDescription[eventLanguage] || '';
    }
    return '';
  }, [productDescription, eventLanguage, language]);

  const nextDisabled = useMemo(() => (
    checkoutQuantity <= 0
    || checkoutQuantity > maxAmount
    || checkoutQuantity > (maxAmountLeft || 0)
  ), [checkoutQuantity, maxAmount, maxAmountLeft]);

  return (
    <>
      <TicketContainer $disabled={disabled}>
        <TickerHeader>
          <StatusBar
            status={status}
            hasMessage={Boolean(message)}
            setModalOpen={setModalOpen}
          />
          <Spacer />
          {!isFreemium && (
            <Price>
              {`${price.toFixed(2)}${symbol} ${currency.toUpperCase()}`}
            </Price>
          )}
        </TickerHeader>
        <TicketContent>
          <TicketIcon
            src={waitingLineType === WaitingLineType.Gold ? goldIcon : silverIcon}
          />
          <TicketContentText>
            <TicketTitle>
              {name[language] || name[eventLanguage]}
            </TicketTitle>
            <TicketDescription>
              {productDescriptionText}
            </TicketDescription>
          </TicketContentText>
        </TicketContent>
        <div>
          <TicketContent>
            {(!isFreemium || (isFreemium && limitedQuantity)) && (
              <QuantityInfos
                available={limitedQuantity ? amountLeft : undefined}
                maxAmount={maxAmount}
                showLimitPerTransaction={!isFreemium}
              />
            )}
          </TicketContent>
          <TicketActions>
            {!isFreemium && (
              <NumberInput
                value={checkoutQuantity}
                onChange={setCheckoutQuantity}
                max={maxAmountLeft}
                min={1}
                disabled={disabled}
              />
            )}
            <Spacer />
            <PrimaryButton
              disabled={nextDisabled}
              onClick={() => (isFreemium
                ? setLocation(`/waitingLine/${id}`)
                : checkout({
                  amount: checkoutQuantity,
                  isFreemium: false,
                  waitingLineId: id,
                })
              )}
            >
              <FormattedMessage id={isFreemium ? 'tickets_enter' : 'tickets_checkout'} />
            </PrimaryButton>
          </TicketActions>
        </div>
      </TicketContainer>
      {message && (
        <InformationModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          message={message}
        />
      )}
    </>
  );
}

export default Ticket;
