const policiesEn: { [name: string]: string } = {
  privacy_policy: 'Politique de confidentialité',
  privacy_policy_1_content: 'MovOn.VIP (le "Site") est exploité par Novom Interactive (l\'"Opérateur"). La présente politique de confidentialité (la "Politique") décrit comment l\'Opérateur collecte, utilise et divulgue vos renseignements personnels. En accédant et en utilisant le Site, vous acceptez d\'être lié par la présente Politique. Si vous n\'acceptez pas la présente Politique, vous ne devez pas accéder au Site ou l\'utiliser.',
  privacy_policy_1_title: '1. Introduction',
  privacy_policy_2_content: 'L\'Opérateur collecte les renseignements personnels suivants auprès de vous :'
    + '<ul>'
    + '<li><b>Renseignements personnels que vous fournissez volontairement</b> : Il s\'agit notamment de votre adresse courriel, votre numéro de téléphone, de votre mot de passe et d\'autres informations que vous fournissez lorsque vous créez un compte sur le Site ou lorsque vous utilisez certaines fonctionnalités du Site.</li>'
    + '<li><b>Renseignements personnels collectés automatiquement</b> : Lorsque vous utilisez le Site, l\'Opérateur collecte automatiquement certains renseignements personnels vous concernant, tels que votre adresse IP, votre navigateur Web et votre système d\'exploitation. Ces renseignements sont utilisés pour améliorer les performances du Site et à des fins d\'analyse.</li>'
    + '<li><b>Renseignements personnels provenant de tiers</b> : L\'Opérateur ne peut pas collecter des renseignements personnels vous concernant auprès de tiers, tels que des réseaux sociaux.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Collecte de renseignements personnels',
  privacy_policy_3_content: 'L\'Opérateur utilise vos renseignements personnels aux fins suivantes :'
    + '<ul>'
    + '<li><b>Pour vous fournir les services du Site</b> : L\'Opérateur utilise vos renseignements personnels pour vous fournir les services du Site, tels que la création d\'un compte, la publication de contenu et la communication avec d\'autres utilisateurs.</li>'
    + '<li><b>Pour améliorer le Site</b> : L\'Opérateur utilise vos renseignements personnels pour améliorer le Site et ses services.</li>'
    + '<li><b>Pour vous contacter</b> : L\'Opérateur peut utiliser vos renseignements personnels pour vous contacter au sujet du Site ou de vos comptes.</li>'
    + '<li><b>À des fins de marketing</b> : L\'Opérateur peut utiliser vos renseignements personnels pour vous envoyer des communications marketing, telles que un courriels de remerciements et des sondages de satisfaction. Dans toutes les communications, le processus de désabonnement est simple et accessible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Utilisation des renseignements personnels',
  privacy_policy_4_content: 'L\'Opérateur peut divulguer vos renseignements personnels aux tiers suivants :'
    + '<ul>'
    + '<li><b>Fournisseurs de services tiers</b> : L\'Opérateur peut divulguer vos renseignements personnels à des fournisseurs de services tiers qui l\'aident à exploiter le Site, tels que des fournisseurs d\'hébergement Web et des fournisseurs de services de paiement.</li>'
    + '<li><b>Autorités gouvernementales</b> : L\'Opérateur peut divulguer vos renseignements personnels aux autorités gouvernementales si la loi l\'exige ou si l\'Opérateur croit de bonne foi que la divulgation est nécessaire pour protéger ses droits ou les droits de tiers.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Divulgation des renseignements personnels',
  privacy_policy_5_content: 'Vous pouvez exercer les droits suivants concernant vos renseignements personnels :'
    + '<ul>'
    + '<li><b>Accès à vos renseignements personnels</b> : Vous avez le droit d\'accéder à vos renseignements personnels que l\'Opérateur détient.</li>'
    + '<li><b>Correction de vos renseignements personnels</b> : Vous avez le droit de faire corriger les renseignements personnels inexacts ou incomplets que l\'Opérateur détient.</li>'
    + '<li><b>Suppression de vos renseignements personnels</b> : Vous avez le droit de demander que l\'Opérateur supprime vos renseignements personnels.</li>'
    + '<li><b>Limitation du traitement de vos renseignements personnels</b> : Vous avez le droit de demander que l\'Opérateur limite le traitement de vos renseignements personnels.</li>'
    + '<li><b>Opposition au traitement de vos renseignements personnels</b> : Vous avez le droit de vous opposer au traitement de vos renseignements personnels par l\'Opérateur.</li>'
    + '</ul>'
    + 'Pour exercer vos droits, veuillez contacter Eric Foley à <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Vos choix',
  privacy_policy_6_content: 'L\'Opérateur prend des mesures de sécurité raisonnables pour protéger vos renseignements personnels contre la perte, le vol, l\'utilisation abusive, la divulgation non autorisée, l\'accès, la modification ou la destruction.',
  privacy_policy_6_title: '6. Sécurité des renseignements personnels',
  privacy_policy_7_content: 'L\'Opérateur conservera vos renseignements personnels pendant le temps nécessaire pour remplir les fins pour lesquelles ils ont été collectés, ou pendant une période plus longue si la loi l\'exige ou le permet.',
  privacy_policy_7_title: '7. Conservation des renseignements personnels',
  privacy_policy_8_content: 'L\'Opérateur peut modifier la présente Politique à tout moment en publiant la Politique modifiée sur le Site. Les modifications de la Politique entreront en vigueur dès leur publication. Vous êtes responsable de la lecture régulière de la Politique pour vous assurer que vous êtes au courant de toutes les modifications.',
  privacy_policy_8_title: '8. Modifications de la Politique',
  privacy_policy_9_content: 'Si vous avez des questions concernant la présente Politique, veuillez contacter Eric Foley à <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contact',
};

export default policiesEn;
