import { useApolloClient } from '@apollo/client';
import { AuthenticatedApolloClient } from 'lib/graphql/apolloClient';
import {
  LoginMagicTokenMutation,
  RefreshAuthMutation,
  useLoginMagicTokenMutation,
  useLogoutMutation,
  useRefreshAuthMutation,
} from 'lib/graphql/graphql';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Loader from '../Loading/Loader';
import AuthenticatorContext, { IAuthenticatorContext } from './AuthenticatorContext';

const REFRESH_INTERVAL = 14.5 * 60 * 1000;

export interface AuthenticatorProps {
  children: JSX.Element
}

type Payload = RefreshAuthMutation['refreshAuth'] | LoginMagicTokenMutation['loginMagicToken'];
type PayloadWithoutToken = Omit<Payload, 'authToken'>;

function Authenticator(props: AuthenticatorProps) {
  const { children } = props;

  const refreshHandle = useRef<number>();
  const apolloClient = useApolloClient() as AuthenticatedApolloClient;

  const [login] = useLoginMagicTokenMutation();
  const [logout] = useLogoutMutation();
  const [refresh] = useRefreshAuthMutation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [performingLogin, setPerformingLogin] = useState(true);
  const [authPayload, setAuthPayload] = useState<PayloadWithoutToken>();

  const refreshAuth = useCallback(async () => {
    try {
      const payload = await refresh();
      return payload;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return undefined;
    }
  }, [refresh]);

  const stopRefreshLoop = useCallback(() => {
    clearInterval(refreshHandle.current);
  }, []);

  const handleAuthPayload = useCallback((payload: Payload) => {
    const { authToken, ...payloadData } = payload;
    apolloClient.setToken(authToken);
    setAuthPayload(payloadData);
  }, [apolloClient]);

  const startRefreshLoop = useCallback(() => {
    refreshHandle.current = window.setInterval(async () => {
      const payload = await refreshAuth();
      if (payload?.data) {
        handleAuthPayload(payload.data.refreshAuth);
      } else {
        stopRefreshLoop();
        setLoggedIn(false);
      }
    }, REFRESH_INTERVAL);
  }, [handleAuthPayload, refreshAuth, stopRefreshLoop]);

  const performLogin = useCallback(async (token: string) => {
    setPerformingLogin(true);
    try {
      const payload = await login({ variables: { token } });
      if (payload.data) {
        startRefreshLoop();
        handleAuthPayload(payload.data.loginMagicToken);
        setLoggedIn(true);
      }
    } catch (err) {
      console.error(err);
    }
    setPerformingLogin(false);
  }, [handleAuthPayload, login, startRefreshLoop]);

  const performLogout = useCallback(async () => {
    await logout();
    stopRefreshLoop();
    setLoggedIn(false);
    apolloClient.setToken(undefined);
  }, [apolloClient, logout, stopRefreshLoop]);

  useEffect(() => {
    async function initialRefresh() {
      const payload = await refreshAuth();
      if (payload?.data) {
        startRefreshLoop();
        handleAuthPayload(payload.data.refreshAuth);
        setLoggedIn(true);
      }
      setPerformingLogin(false);
    }

    initialRefresh();
  }, [handleAuthPayload, refreshAuth, startRefreshLoop]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('magic_token')) {
      performLogin(params.get('magic_token')!).then(() => {
        window.history.replaceState({}, '', window.location.href.split('?')[0]);
      });
    }
  }, [performLogin]);

  const authState = useMemo<IAuthenticatorContext>(() => ({
    authPayload,
    loggedIn,
    logout: performLogout,
  }), [
    authPayload,
    loggedIn,
    performLogout,
  ]);

  return (
    <Loader isLoading={performingLogin}>
      <AuthenticatorContext.Provider value={authState}>
        {children}
      </AuthenticatorContext.Provider>
    </Loader>
  );
}

export default Authenticator;
