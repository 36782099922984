import { AlertContext } from 'providers/AlertProvider/AlertContext';
import useCart from 'providers/CartProvider/useCart';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CountdownContainer, CountdownText } from './styles';

function CountdownTimer() {
  const [timer, setTimer] = useState<string | null>();
  const { setMessage } = useContext(AlertContext);
  const { clearOrder: cancelOrder } = useContext(TicketingContext);
  const { expirationTime, setExpirationTime } = useCart();

  useEffect(() => {
    if (expirationTime) {
      const interval = setInterval(() => {
        const time = expirationTime.diffNow();
        setTimer(time.toFormat('mm:ss'));
        if (time.milliseconds <= 0) {
          setMessage('waiting_line_reservation_expired');
          setTimer(null);
          setExpirationTime(null);
          cancelOrder();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [expirationTime, setMessage, cancelOrder, setExpirationTime, setTimer]);

  if (!expirationTime) return null;
  return (
    <CountdownContainer>
      <CountdownText>
        <FormattedMessage id="cart_countdown" values={{ time: timer }} />
      </CountdownText>
    </CountdownContainer>
  );
}

export default CountdownTimer;
