import { ContactInfoType } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { Order } from 'providers/TicketingProvider/TicketingContext';
import { createContext } from 'react';

export interface ICartContext {
  communication: ContactInfoType
  optInNotifications: boolean
  setCommunication: (communication: ContactInfoType) => void
  setOptInNotifications: (optIn: boolean) => void
  stripeCheckoutReturnUrl: string | null
  stripeCheckoutSessionClientSecret: string | null
  reserveTickets: (order: Order) => Promise<void> | void
  cancelTickets: () => Promise<void>
  createCheckoutSession: () => Promise<void>
  expirationTime: DateTime | null
  setExpirationTime: (expirationTime: DateTime | null) => void
  obnlId: string | null
  setObnlId: (impact: string | null) => void
}

export const DefaultCartContext: ICartContext = {
  communication: ContactInfoType.Email,
  optInNotifications: false,
  reserveTickets: async () => {},
  createCheckoutSession: async () => {},
  cancelTickets: async () => {},
  setCommunication: () => {},
  setOptInNotifications: () => {},
  stripeCheckoutReturnUrl: null,
  stripeCheckoutSessionClientSecret: null,
  expirationTime: null,
  setExpirationTime: () => {},
  obnlId: null,
  setObnlId: () => {},
};

const CartContext = createContext<ICartContext>(DefaultCartContext);

export default CartContext;
