import { Cloudinary } from '@cloudinary/url-gen';
import settings from 'lib/settings';

const { cloudName } = settings.cloudinary;

const cloudinaryClient = new Cloudinary({
  cloud: {
    cloudName,
  },
});

export default cloudinaryClient;
