import { createContext } from 'react';

export interface IAlertContext {
  message?: string
  setMessage: (message: string | undefined) => void
}

export const defaultAlertContext: IAlertContext = {
  setMessage: () => {},
};

export const AlertContext = createContext(defaultAlertContext);
