import CheckoutForm from 'components/CheckoutForm/CheckoutForm';
import { CardButtons, CardButtonSpacer } from 'modules/Cart/styles';
import { FormattedMessage } from 'react-intl';
import { OutlinedSecondaryButton } from 'styles/styles';

import { Container } from './styles';

interface PaymentProps {
  onBack: () => void
  onComplete: () => void
}

function Payment(props: PaymentProps) {
  const { onBack, onComplete } = props;

  return (
    <Container>
      <CheckoutForm onComplete={onComplete} />
      <CardButtonSpacer />
      <CardButtons>
        <OutlinedSecondaryButton onClick={onBack}>
          <FormattedMessage id="general_back" />
        </OutlinedSecondaryButton>
      </CardButtons>
    </Container>
  );
}

export default Payment;
