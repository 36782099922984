type PolicyType = {
  title: string;
  content: string;
};

export default [
  {
    content: 'privacy_policy_1_content',
    title: 'privacy_policy_1_title',
  },
  {
    content: 'privacy_policy_2_content',
    title: 'privacy_policy_2_title',
  },
  {
    content: 'privacy_policy_3_content',
    title: 'privacy_policy_3_title',
  },
  {
    content: 'privacy_policy_4_content',
    title: 'privacy_policy_4_title',
  },
  {
    content: 'privacy_policy_5_content',
    title: 'privacy_policy_5_title',
  },
  {
    content: 'privacy_policy_6_content',
    title: 'privacy_policy_6_title',
  },
  {
    content: 'privacy_policy_7_content',
    title: 'privacy_policy_7_title',
  },
  {
    content: 'privacy_policy_8_content',
    title: 'privacy_policy_8_title',
  },
  {
    content: 'privacy_policy_9_content',
    title: 'privacy_policy_9_title',
  },
] as PolicyType[];
