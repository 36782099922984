type TermType = {
  title: string;
  content: string;
};

export default [
  {
    content: 'terms_conditions_1_content',
    title: 'terms_conditions_1_title',
  },
  {
    content: 'terms_conditions_2_content',
    title: 'terms_conditions_2_title',
  },
  {
    content: 'terms_conditions_3_content',
    title: 'terms_conditions_3_title',
  },
  {
    content: 'terms_conditions_4_content',
    title: 'terms_conditions_4_title',
  },
  {
    content: 'terms_conditions_5_content',
    title: 'terms_conditions_5_title',
  },
  {
    content: 'terms_conditions_6_content',
    title: 'terms_conditions_6_title',
  },
  {
    content: 'terms_conditions_7_content',
    title: 'terms_conditions_7_title',
  },
  {
    content: 'terms_conditions_8_content',
    title: 'terms_conditions_8_title',
  },
  {
    content: 'terms_conditions_9_content',
    title: 'terms_conditions_9_title',
  },
  {
    content: 'terms_conditions_10_content',
    title: 'terms_conditions_10_title',
  },
] as TermType[];
