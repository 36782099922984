import EastIcon from '@mui/icons-material/East';
import { Grid } from '@mui/material';
import RoutesEnum from 'lib/constants/routesEnum';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import {
  CauseContainer,
  ContentContainer,
  LogoContainer,
  NextButton,
  ObtainContainer,
  OnboardingContainer,
} from './styles';

function Onboarding() {
  const [, navigate] = useLocation();

  const onClickNext = () => {
    navigate(RoutesEnum.TICKETS);
  };

  return (
    <OnboardingContainer>
      <ContentContainer>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <LogoContainer />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ObtainContainer>
              <FormattedMessage id="onboarding_obtain" />
            </ObtainContainer>
          </Grid>
          <Grid item xs={12} lg={12}>
            <CauseContainer>
              <FormattedMessage id="onboarding_cause" />
            </CauseContainer>
          </Grid>
          <Grid item xs={12} lg={12}>
            <NextButton
              onClick={onClickNext}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <EastIcon />
            </NextButton>
          </Grid>
        </Grid>
      </ContentContainer>
    </OnboardingContainer>
  );
}

export default Onboarding;
