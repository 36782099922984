import styled from 'styled-components';

export const SummaryItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SummaryItemContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;

export const SummaryItemInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryItemTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: bold;
  margin: 0;
`;

export const SummaryItemPrice = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  margin: 0;
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.palette.custom.lightGray};
  height: 2px;
`;

export const MoneyContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const MoneySection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const MoneyTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: bold;
`;

export const MoneyPrice = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
`;
