import Cart from 'modules/Cart/Cart';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import { useContext } from 'react';
import { Redirect, Route } from 'wouter';

function CartRoute() {
  const { order } = useContext(TicketingContext);

  return (
    <Route path="/cart">
      {order ? <Cart /> : <Redirect to="/tickets" />}
    </Route>
  );
}

export default CartRoute;
