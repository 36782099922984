import { useContext } from 'react';

import FeedbackContext from './FeedbackContext';

function useFeedback() {
  const feedback = useContext(FeedbackContext);
  return feedback;
}

export default useFeedback;
