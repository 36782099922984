import { Info } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { WaitingLineStatus } from 'lib/graphql/graphql';

import {
  CircleStatus,
  Container,
  IconButton,
  StatusText,
} from './styles';

interface StatusBarProps {
  hasMessage: boolean
  status: WaitingLineStatus
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function StatusBar(props: StatusBarProps) {
  const { hasMessage, status, setModalOpen } = props;

  const theme = useTheme();

  const statusColor = {
    [WaitingLineStatus.Inactive]: theme.palette.error.main,
    [WaitingLineStatus.Active]: theme.palette.success.main,
    [WaitingLineStatus.Hidden]: 'transparent',
  };

  // TODO countdown until waiting line is available
  return (
    <Container>
      <CircleStatus $background={statusColor[status]} />
      <StatusText>
        {status}
      </StatusText>
      {hasMessage && (
        <IconButton
          disabled={!hasMessage}
          onClick={() => setModalOpen(true)}
        >
          <Info />
        </IconButton>
      )}
    </Container>
  );
}

export default StatusBar;
