import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Text } from '../../styles/styles';
import { Content } from './styles';

export interface ErrorPageProps {
  title: React.ReactNode
}

function ErrorPage(props: ErrorPageProps) {
  const { title } = props;

  return (
    <Grid container height="100%">
      <Content item xs={12}>
        <Text>
          {title}
        </Text>
        <Button onClick={() => window.location.reload()}>
          <FormattedMessage id="reload" />
        </Button>
      </Content>
    </Grid>
  );
}

export default ErrorPage;
