import Settings from './Settings';

const settings: Settings = {
  anyware: {
    licenseKey: 'novom:key',
    rootTopic: 'movon/production',
  },
  cloudinary: {
    cloudName: 'novom-networks',
    projectName: 'MovOnVIP',
  },
  graphql: {
    url: 'https://data.movon.vip/graphql',
  },
  logLevel: 'DEBUG',
  stripe: {
    publishableKey: 'pk_live_51NOLm9IzTi1pf1P8jmkllf8Dnae7Nz5EWjPx42y4ms1H7805s6TjjqW1XuBZktJPRNe9vOgyYKKfo73qUatE18Y800sCa2mC8n',
  },
  support: {
    en: 'https://support.movon.vip/en/hc/3673856758',
    es: 'https://support.movon.vip/es/hc/3673856758',
    fr: 'https://support.movon.vip/fr/hc/3673856758',
  },
};

export default settings;
