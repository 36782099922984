import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { AseProvider } from '@novominteractive/ase-react-apollo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FeedbackProvider from 'components/Feedback/FeedbackProvider';
import aseClient from 'lib/ase/client';
import apolloClient from 'lib/graphql/apolloClient';
import LanguageProvider from 'lib/providers/Language/LanguageProvider';
import AppThemeProvider from 'styles/AppThemeProvider';

import Routes from '../routes/routes';

const queryClient = new QueryClient();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AseProvider client={aseClient}>
        <StyledEngineProvider injectFirst>
          <CssBaseline enableColorScheme />
          <AppThemeProvider>
            <LanguageProvider>
              <QueryClientProvider client={queryClient}>
                <FeedbackProvider>
                  <Routes />
                </FeedbackProvider>
              </QueryClientProvider>
            </LanguageProvider>
          </AppThemeProvider>
        </StyledEngineProvider>
      </AseProvider>
    </ApolloProvider>
  );
}

export default App;
