import { EventStatus, useGetAllObnlsIdsForEventQuery, useGetEventSuspenseQuery } from 'lib/graphql/graphql';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'wouter';

import { DefaultEventContext, EventContext, IEventContext } from './EventContext';

interface TicketingProviderProps {
  children: JSX.Element
}

function EventProvider(props: TicketingProviderProps) {
  const { children } = props;

  const { eventId } = useParams<{ eventId: string }>();
  const [, setLocation] = useLocation();
  const { data } = useGetEventSuspenseQuery({ variables: { id: eventId! }, skip: !eventId });

  const event = data?.getEvent;

  const {
    data: obnlsData,
    loading: obnlsLoading,
    refetch: refetchObnls,
  } = useGetAllObnlsIdsForEventQuery({
    variables: {
      eventId,
    },
    skip: !eventId,
  });

  const contextValue = useMemo<IEventContext>(() => {
    if (data?.getEvent) {
      return {
        ...data?.getEvent!,
        obnls: obnlsData ? obnlsData.getAllObnlsIdsForEvent.obnls : [],
        refetchObnls,
      };
    }
    return DefaultEventContext;
  }, [data, obnlsData, refetchObnls]);

  useEffect(() => {
    if (!obnlsLoading && (!eventId || !event || event.status !== EventStatus.Published)) {
      setLocation('~/');
    }
  }, [event, eventId, obnlsLoading, setLocation]);

  if (!event) return null;

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
}

export default EventProvider;
