import {
  faBalanceScale,
  faBolt,
  faBook,
  faChartLineUp,
  faCity,
  faDove,
  faEarthAmerica,
  faFamily,
  faHandsHelping,
  faHeartbeat,
  faIndustryWindows,
  faPlateWheat,
  faRecycle,
  faTankWater,
  faTree,
  faVenusMars,
  faWater,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export const SDG_ICONS: { [key: string]: IconDefinition } = {
  cities: faCity,
  climate: faEarthAmerica,
  consumption: faRecycle,
  education: faBook,
  energy: faBolt,
  gender: faVenusMars,
  health: faHeartbeat,
  hunger: faPlateWheat,
  industry: faIndustryWindows,
  inequalities: faBalanceScale,
  life_land: faTree,
  partnerships: faHandsHelping,
  peace: faDove,
  poverty: faFamily,
  water: faTankWater,
  water_life: faWater,
  work: faChartLineUp,
};
