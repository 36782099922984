import { ContactInfoType } from 'lib/graphql/graphql';
import CartContext from 'providers/CartProvider/CartContext';
import {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'wouter';

import { ITicketingContext, Order, TicketingContext } from './TicketingContext';

interface TicketingProviderProps {
  children: JSX.Element
}

function TicketingProvider(props: TicketingProviderProps) {
  const { children } = props;

  const [, setLocation] = useLocation();
  const [order, setOrder] = useState<Order>();
  const [returnUrl, setReturnUrl] = useState('');

  const { setCommunication } = useContext(CartContext);

  const checkout = useCallback<ITicketingContext['checkout']>((newOrder) => {
    setOrder(newOrder);
    setReturnUrl(window.location.href.replace(window.location.origin, ''));
    setLocation('/cart');
  }, [setLocation]);

  const updateOrder = useCallback<ITicketingContext['updateOrder']>((updatedFields) => {
    setOrder((current) => {
      if (current) {
        return { ...current, ...updatedFields };
      }
      return undefined;
    });
  }, []);

  const cancelOrder = useCallback<ITicketingContext['clearOrder']>(() => {
    setLocation(`~${returnUrl}`);
    setOrder(undefined);
    setReturnUrl('');
    setCommunication(ContactInfoType.Email);
  }, [returnUrl, setCommunication, setLocation]);

  const removeItem = useCallback<ITicketingContext['removeItem']>(() => {
    // TODO doesn't do anything more for now.
    // TODO Useful if tickets are bought for multiple waitingLine at the same time.
    cancelOrder();
  }, [cancelOrder]);

  const context = useMemo<ITicketingContext>(() => ({
    clearOrder: cancelOrder,
    checkout,
    order,
    removeItem,
    updateOrder,
  }), [cancelOrder, checkout, order, removeItem, updateOrder]);

  return (
    <TicketingContext.Provider value={context}>
      {children}
    </TicketingContext.Provider>
  );
}

export default TicketingProvider;
