import { useCallback } from 'react';
import { ActionButton } from 'styles/styles';

import { Input, NumberInputContainer } from './styles';

interface NumberInputProps {
  max?: number
  min?: number
  onChange: (value: number) => void
  value: number
  disabled?: boolean
}

function NumberInput(props: NumberInputProps) {
  const {
    max = Infinity,
    min = -Infinity,
    onChange,
    value,
    disabled = false,
  } = props;

  const increase = useCallback(() => {
    if (value + 1 <= max) {
      onChange(value + 1);
    }
  }, [max, onChange, value]);

  const decrease = useCallback(() => {
    if (value - 1 >= min) {
      onChange(value - 1);
    }
  }, [min, onChange, value]);

  const setValue = useCallback((newValue: number) => {
    if (newValue <= max && newValue >= min) {
      onChange(newValue);
    }
  }, [max, min, onChange]);

  return (
    <NumberInputContainer $disabled={disabled}>
      <ActionButton
        onClick={() => decrease()}
        disabled={value <= min}
      >
        -
      </ActionButton>
      <Input
        type="number"
        min={min}
        max={max}
        value={disabled ? 0 : value}
        onChange={(event) => setValue(parseInt(event.target.value, 10))}
      />
      <ActionButton
        onClick={increase}
        disabled={value >= max}
      >
        +
      </ActionButton>
    </NumberInputContainer>
  );
}

export default NumberInput;
