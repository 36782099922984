import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';

import messages from '../../i18n';
import LanguageContext, { DefaultLanguageContext, Languages } from './LanguageContext';

export interface LanguageProviderProps {
  children: JSX.Element
}

function LanguageProvider(props: LanguageProviderProps) {
  const { children } = props;

  const defaultLanguage = useMemo(() => {
    const savedLanguage = localStorage.getItem('language') as Languages;
    if (savedLanguage) return savedLanguage;

    // Get the first part of the navigator language (e.g., 'en' from 'en-US')
    const navigatorLanguage = navigator.language.split(/[-_]/)[0] as Languages;

    // If the navigator language is in the list of supported languages, use it;
    // otherwise, use the language from DefaultLanguageContext
    const initialLanguage = Object.values(Languages).includes(navigatorLanguage)
      ? navigatorLanguage
      : DefaultLanguageContext.language;

    return initialLanguage;
  }, []);

  const [language, setLanguage] = useState(defaultLanguage);

  const changeLanguage = useCallback((newLanguage: Languages) => {
    localStorage.setItem('language', newLanguage);
    setLanguage(newLanguage);
  }, []);

  const contextValue = useMemo(() => ({
    language,
    setLanguage: changeLanguage,
  }), [changeLanguage, language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
