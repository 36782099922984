import { Typography } from '@mui/material';
import styled from 'styled-components';

export const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 100;
`;

export const CountdownText = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
`;
