import { FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'styles/styles';

interface RequiredErrorProps {
  error?: FieldError
}

function FormErrors(props: RequiredErrorProps) {
  const { error } = props;

  if (error) {
    if (error.type === 'required') {
      return (
        <ErrorMessage>
          <FormattedMessage id="generic_form_error_required" />
        </ErrorMessage>
      );
    }

    return (
      <ErrorMessage>
        {error.message}
      </ErrorMessage>
    );
  }
  return null;
}

export default FormErrors;
