import { EventStatus, GetEventQuery } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { createContext } from 'react';

export type IEventContext = NonNullable<GetEventQuery['getEvent']> & {
  obnls: string[];
  refetchObnls: () => void;
};

export const DefaultEventContext: IEventContext = {
  id: '',
  name: {
    en: '',
    fr: '',
    es: '',
  },
  endTime: DateTime.now().toString(),
  location: '',
  startTime: DateTime.now().toString(),
  timezone: '',
  userId: '',
  status: EventStatus.Draft,
  obnls: [],
  refetchObnls: () => {},
  languages: [],
  allowObnlChoice: false,
};

export const EventContext = createContext(DefaultEventContext);
