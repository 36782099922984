import { motion } from 'framer-motion';
import styled from 'styled-components';

export const OnboardingContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: lightblue;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

export const LogoContainer = styled.div`
  background: purple;
  width: 8rem;
  height: 5rem;
  border-radius: 10px;
`;

export const ObtainContainer = styled.div`
  width: 15rem;
  height: auto;
  padding-top: 1rem;
  color: white;
  font-size: 40px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
`;

export const CauseContainer = styled.div`
  width: 15rem;
  height: auto;
  padding-top: 0.5rem;
  color: white;
  font-size: 20px;
  text-align: left;
  line-height: 1.2;
`;

export const NextButton = styled(motion.div)`
  width: 4rem;
  height: 4rem;
  background: blue;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  box-shadow: 0 0 10px 0.5px black;
  color: lightblue;
`;
