import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Policies from './Policies';
import {
  CenteredContainer,
  CopyrightContainer,
  HeaderContainer,
  MailTo,
  Separator,
  TermContainer,
  TermContent,
  TermsContainer,
  TermTitle,
  TitleTerms,
} from './styles';
import Terms from './Terms';

interface LegalAgreementsProps {
  onClose: () => void;
  title: string;
}

type LegalAgreement = {
  title: string;
  content: string;
};

function ul(chunks: React.ReactNode) {
  return (
    <ul>{chunks}</ul>
  );
}

function li(chunks: React.ReactNode) {
  return (
    <li>{chunks}</li>
  );
}

function b(chunks: React.ReactNode) {
  return (
    <b>{chunks}</b>
  );
}

function mailto(chunks: React.ReactNode) {
  return (
    <MailTo color="secondary" href={`mailto:${chunks}`}>{chunks}</MailTo>
  );
}

export const LegalAgreementsMap: { [key: string]: LegalAgreement[] } = {
  terms_conditions: Terms,
  privacy_policy: Policies,
};

function LegalAgreements(props: LegalAgreementsProps) {
  const { onClose, title } = props;
  const items = LegalAgreementsMap[title];
  const currentYear = new Date().getFullYear();

  return (
    <CenteredContainer>
      <HeaderContainer>
        <TitleTerms>
          <FormattedMessage id={title} />
        </TitleTerms>
        <IconButton color="primary" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </HeaderContainer>
      <TermsContainer>
        {items.map((item) => (
          <TermContainer key={item.title}>
            <TermTitle>
              <FormattedMessage id={item.title} />
            </TermTitle>
            <TermContent>
              <FormattedMessage
                id={item.content}
                values={{
                  ul,
                  li,
                  b,
                  mailto,
                }}
              />
            </TermContent>
          </TermContainer>
        ))}
      </TermsContainer>
      <CopyrightContainer>
        <Separator />
        <FormattedMessage id="terms_conditions_copyright" values={{ year: currentYear }} />
      </CopyrightContainer>
    </CenteredContainer>
  );
}

export default LegalAgreements;
