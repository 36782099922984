import { useMemo, useState } from 'react';

import { AlertContext, IAlertContext } from './AlertContext';
import AlertPage from './AlertPage/AlertPage';

interface AlertProviderProps {
  children: JSX.Element
}

function AlertProvider(props: AlertProviderProps) {
  const { children } = props;

  const [message, setMessage] = useState<string | undefined>(undefined);

  const context = useMemo<IAlertContext>(() => ({
    message,
    setMessage,
  }), [message, setMessage]);

  return (
    <AlertContext.Provider value={context}>
      {message ? <AlertPage /> : children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
