import { Alert, Snackbar } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import FeedbackContext, { Feedback } from './FeedbackContext';

interface FeedbackProviderProps {
  children: JSX.Element
}

function FeedbackProvider(props: FeedbackProviderProps) {
  const { children } = props;

  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState<Feedback | null>(null);

  const createFeedback = useCallback((config: Feedback) => {
    setFeedback({
      ...config,
      duration: config.duration !== undefined ? config.duration : 6000,
    });
    setVisible(true);
  }, []);

  useEffect(() => {
    if (!visible) {
      setFeedback(null);
    }
  }, [visible]);

  return (
    <>
      <FeedbackContext.Provider value={createFeedback}>
        {children}
      </FeedbackContext.Provider>
      {feedback && (
        <Snackbar
          anchorOrigin={feedback.anchor}
          autoHideDuration={feedback.duration}
          open={visible}
          onClose={() => setVisible(false)}
        >
          <Alert
            onClose={() => setVisible(false)}
            variant="filled"
            severity={feedback.severity}
          >
            {feedback.content}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default FeedbackProvider;
