import getMaxAmountLeft from 'lib/getMaxAmountLeft';
import { FormattedMessage } from 'react-intl';

import {
  QuantityAccentText,
  QuantityInfosContainer,
  QuantitySection,
  QuantityText,
} from './styles';

interface QuantityInfosProps {
  available?: number
  maxAmount?: number
  showLimitPerTransaction?: boolean
}

function QuantityInfos(props: QuantityInfosProps) {
  const { available, maxAmount, showLimitPerTransaction = true } = props;

  const isSoldOut = available !== undefined && available <= 0;

  const maxAmountLeft = getMaxAmountLeft(maxAmount, available);

  return (
    <QuantityInfosContainer>
      <QuantitySection>
        {available !== undefined && (
          !isSoldOut ? (
            <>
              <QuantityText>
                <FormattedMessage id="tickets_quantity" />
              </QuantityText>
              <QuantityAccentText>
                <FormattedMessage id="tickets_quantity_available" values={{ quantity: available }} />
              </QuantityAccentText>
            </>
          ) : (
            <QuantityText>
              <FormattedMessage id="tickets_quantity_sold_out" />
            </QuantityText>
          )
        )}
      </QuantitySection>
      {maxAmount && (!isSoldOut || available === undefined) && showLimitPerTransaction && (
        <QuantitySection>
          <QuantityText>
            <FormattedMessage id="tickets_group_size" />
          </QuantityText>
          <QuantityAccentText>
            <FormattedMessage id="tickets_group_size_value" values={{ limit: maxAmountLeft }} />
          </QuantityAccentText>
        </QuantitySection>
      )}
    </QuantityInfosContainer>
  );
}

export default QuantityInfos;
