import logo from 'assets/images/logo/movon-tag-horizontal-small.png';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';

import {
  HeaderContainer,
  HeaderTitle,
  HeaderTop,
  Logo,
} from './styles';

interface HeaderProps {
  title?: React.ReactNode
  showLogo?: boolean
}

function Header(props: HeaderProps) {
  const { title, showLogo = true } = props;

  return (
    <HeaderContainer>
      <HeaderTop>
        <Logo
          src={logo}
          onClick={() => window.open('https://movon.vip/', '_blank')}
          $hidden={!showLogo}
        />
        <LanguageSelector />
      </HeaderTop>
      {title && (
        <HeaderTitle>
          {title}
        </HeaderTitle>
      )}
    </HeaderContainer>
  );
}

export default Header;
