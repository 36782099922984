import { SmallText, TicketNumberContainer } from 'modules/FreemiumWaitingLine/styles';
import { FormattedMessage } from 'react-intl';

import { TicketNumberText } from './styles';

export interface TicketNumberProps {
  ticketNumber: string;
}

export default function TicketNumber({ ticketNumber }: TicketNumberProps) {
  return (
    <TicketNumberContainer>
      <SmallText>
        <FormattedMessage id="waiting_line_waiting_ticket_number" />
      </SmallText>
      <TicketNumberText>
        {ticketNumber.padStart(5, '0')}
      </TicketNumberText>
    </TicketNumberContainer>
  );
}
