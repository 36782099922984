import {
  createTheme,
  PaletteOptions,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { useMemo } from 'react';

import { Background } from './styles';

function AppThemeProvider({ children }: { children: JSX.Element }) {
  const theme : Theme = useMemo(() => {
    const palette: PaletteOptions = {
      mode: 'light',
      primary: {
        main: '#00B092',
        contrastText: '#fff',
      },
      secondary: {
        main: '#0069B1',
        contrastText: '#fff',
      },
      background: {
        default: '#0069B1',
        paper: '#ffffff',
      },
      text: {
        primary: '#000',
      },
      error: {
        main: '#e85050',
        contrastText: '#ffffff',
      },
      warning: {
        main: '#f67044',
        contrastText: '#ffffff',
      },
      success: {
        main: '#72d59c',
        contrastText: '#ffffff',
      },
      custom: {
        background: {
          contrastText: '#ffffff',
          dark: '#001f3e',
        },
        lightGray: '#eeeeee',
        gray: '#4D4F53',
        lightSecondary: {
          main: '#0069B1',
          contrastText: '#fff',
        },
        secondaryAccent: {
          main: '#56B7E6',
        },
      },
    };

    return createTheme({
      palette,
      fontSizes: {
        normal: '0.8em',
        big: '1em',
        bigger: '1.5em',
        biggest: '2.0em',
      },
      typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Background>
        {children}
      </Background>
    </ThemeProvider>
  );
}

export default AppThemeProvider;
