import EventLayout from 'components/Layout/EventLayout';
import Called from 'modules/FreemiumWaitingLine/Called/Called';
import Register from 'modules/FreemiumWaitingLine/Register/Register';
import Wait from 'modules/FreemiumWaitingLine/Wait/Wait';
import Tickets from 'modules/Tickets/Tickets';
import CartProvider from 'providers/CartProvider/CartProvider';
import TicketingProvider from 'providers/TicketingProvider/TicketingProvider';
import { Route } from 'wouter';

import CartRoute from './CartRoute';

function TicketingRoutes() {
  return (
    <TicketingProvider>
      <CartProvider>
        <EventLayout>
          <Route path="/tickets" component={Tickets} />
          <Route path="/waitingLine/:waitingLineId" component={Register} />
          <Route path="/wait" component={Wait} />
          <Route path="/called" component={Called} />
          <CartRoute />
        </EventLayout>
      </CartProvider>
    </TicketingProvider>
  );
}

export default TicketingRoutes;
