import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import settings from 'lib/settings';

interface AuthenticatedApolloClientParams {
  includeCredentials: boolean
  url: string,
}

export class AuthenticatedApolloClient extends ApolloClient<NormalizedCacheObject> {
  public setToken: (token: string | undefined) => void;

  constructor({ includeCredentials }: AuthenticatedApolloClientParams) {
    const cache = new InMemoryCache();
    let authToken: string | undefined;

    function setToken(token: string | undefined) {
      authToken = token;
    }

    super({
      cache,
      link: from([
        new MultiAPILink({
          endpoints: {
            auth: `${settings.graphql.url}/auth`,
            default: settings.graphql.url,
          },
          defaultEndpoint: 'default',
          createHttpLink: () => createHttpLink({
            credentials: includeCredentials ? 'include' : undefined,
          }),
          httpSuffix: '',
          getContext: (_, getCurrentContext) => {
            const { headers } = getCurrentContext();
            if (authToken) {
              return {
                headers: {
                  ...headers,
                  authorization: authToken,
                },
              };
            }
            return { headers };
          },
        }),
      ]),
    });
    this.setToken = setToken;
  }
}

const apolloClient = new AuthenticatedApolloClient({
  includeCredentials: true,
  url: settings.graphql.url,
});

export default apolloClient;
