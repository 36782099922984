import styled from 'styled-components';
import { SecondaryButton } from 'styles/styles';

import { SmallText, TicketNumberContainer } from '../styles';

export const Welcome = styled(SmallText)`
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

export const Time = styled(Welcome)`
  font-weight: bold;
`;

export const UpgradeContainer = styled(TicketNumberContainer)`
  border: unset;
  background-color: ${({ theme }) => theme.palette.custom.background.dark};
  position: relative;
`;

export const UpgradeBackgroundImage = styled.img`
  position: absolute;
  height: 90%;
  bottom: 0;
`;

export const SavedTime = styled(SmallText)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  z-index: 1;
`;

export const UpgradeFor = styled(SavedTime)`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

export const Price = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
  font-size: ${({ theme }) => theme.fontSizes.biggest};
  z-index: 1;
`;

export const UpgradeButton = styled(SecondaryButton)`
  z-index: 1;
  display: flex;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 8px;
  box-shadow: 1px 1px 0 1px ${({ theme }) => theme.palette.custom.secondaryAccent.main};
`;

export const UpgradeIcon = styled.img`
  height: 32px;
  margin-left: -16px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

export const UpgradeSmallPrint = styled(SmallText)`
  z-index: 1;
`;

export const Spacer = styled.div`
  flex: 1;
`;
