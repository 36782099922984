/* cSpell:language en */
import policiesEn from './LegalAgreements/PoliciesEn';
import termsEn from './LegalAgreements/TermsEn';

const messages: I18NMessages = {
  about_movon: 'About MovOn.VIP',

  alert_something_wrong: 'Something went wrong!',
  alert_waiting_line_not_active: 'The queue is currently inactive. Please try again later.',
  alert_waiting_line_not_enough_tickets: 'There are not enough passes available. Thank you for your understanding.',
  alert_waiting_line_reservation_expired: 'The allowed time to buy your passes has expired. Please try again.',
  alert_return: 'Return to passes',

  cancel_order_title: 'Are you sure you want to cancel your order?',
  cancel_order_body: 'Leaving now will cancel your order and forfeit your queue position. Go back?',

  cart_choose_for_me: 'Choose for me',
  cart_choose: 'I want to choose',
  cart_countdown: 'Time left: {time}',
  cart_email: 'Email',
  cart_sms: 'SMS',
  cart_summary: 'Summary',
  cart_summary_movon: 'MovOn.VIP undertakes to pay 33% of revenue to {obnl}',
  cart_impact: 'Impact',
  cart_impact_description: 'For each MovOn.VIP pass sold,',
  cart_impact_follow: '33% goes to a charity cause',
  cart_communication: 'Contact',
  cart_communication_description: 'Choose your preferred communication method to receive updates on the status of your passes.',
  cart_communication_opt_in_notifications: 'Receive notifications from the organizer',
  cart_payment: 'Payment',
  cart_remove: 'Remove',
  cart_subtotal: 'Subtotal',
  cart_tax: 'Taxes',

  event_starts: 'Valid from',
  event_ends: 'Until',
  event_location: 'Event location',
  event_no_waiting_line_available: 'No queue has been created for this event.',
  event_timezone: 'Timezone',

  get_vip_access: 'Get a VIP access',

  general_back: 'Back',
  general_cancel: 'Cancel',
  general_close: 'Close',
  generic_form_error_required: 'This field is required',
  general_next: 'Next',
  general_no: 'No',
  general_select: 'Select',
  general_yes: 'Yes',

  movon_title: 'MovOn.VIP',

  obnl_cities: 'Sustainable cities and communities',
  obnl_climate: 'Climate action',
  obnl_consumption: 'Responsible consumption and production',
  obnl_education: 'Quality education',
  obnl_energy: 'Affordable and clean energy',
  obnl_gender: 'Gender equality',
  obnl_health: 'Good health and well-being',
  obnl_hunger: 'Zero hunger',
  obnl_industry: 'Industry, innovation, and infrastructure',
  obnl_inequalities: 'Reduced inequalities',
  obnl_life_land: 'Life on land',
  obnl_partnerships: 'Partnerships for the goals',
  obnl_peace: 'Peace, justice, and strong institutions',
  obnl_poverty: 'No poverty',
  obnl_water: 'Clean water and sanitation',
  obnl_water_life: 'Life below water',
  obnl_work: 'Decent work and economic growth',

  onboarding_cause: 'Support a cause that is close to your heart.',
  onboarding_obtain: 'Get VIP access',

  pass_congratulations: 'Congratulations ',
  pass_download_pass: 'or download your pass',
  pass_owner_gold: '{name}\'s VIP Gold Pass',
  pass_owner_silver: '{name}\'s VIP Silver Pass',
  pass_owner_silver_no_name: 'Your Pass Silver VIP',
  pass_owner_gold_no_name: 'Your Pass Gold VIP',
  pass_gold_vip: 'You are now a Gold VIP',
  pass_silver_vip: 'You are now a Silver VIP',
  pass_help: 'Need help?',
  pass_line_status: 'Waiting line status',
  pass_resend_confirmation: 'Resend confirmation',
  pass_resend_error: 'An error occurred while resending your confirmation. Please try again later.',
  pass_resend_success: 'Your confirmation has been successfully resent.',
  pass_sent_to: 'Your pass was sent to {email}',
  pass_show_pass: 'Show your pass to a cashier to skip the line',
  pass_what_next: 'What\'s next?',

  passes: '{quantity} pass{quantity, plural, one {} other {es}}',

  pdq_avoid_waiting_line: 'Avoid the queue!',
  pdq_in_a_hurry: 'In a hurry?',

  placeholder_email_address: 'Email address',
  placeholder_sms_number: 'Telephone number',

  payment_information: 'Payment information',

  reload: 'Reload',

  support_cause: 'Support a cause that is close to your heart.',

  support_title: 'Support',
  support_name: 'Name (optional)',
  support_email: 'Email',
  support_subject: 'Subject',
  support_message: 'Message',
  support_send: 'Send',
  support_sent_error: 'An error occurred while sending your message. Please try again later or send an email to help@movon.vip.',
  support_sent_success: 'Your message has been sent successfully. We will get back to you as soon as possible.',

  tickets_enter: 'Enter',
  tickets_checkout: 'Checkout',
  tickets_gold_title: 'Skip-the-line',
  tickets_group_size_value: '{limit} pass{limit, plural, one {} other {es}}',
  tickets_group_size: 'Limit per transaction',
  tickets_quantity: 'Quantity',
  tickets_quantity_available: '{quantity} available',
  tickets_quantity_sold_out: 'All passes are sold out',
  tickets_silver_title: 'Save your spot',

  use_camera_app_qr: 'Use your <tag>camera</tag> app to scan the QR code.',

  waiting_line_called: 'It\'s your turn!',
  waiting_line_claim_free_error: 'An error occurred, please try again later.',
  waiting_line_claim_free_invalid_email: 'Your email address must at least contain an @.',
  waiting_line_register: 'Register!',
  waiting_line_register_use_email: 'Use an email address',
  waiting_line_register_use_sms: 'Use a telephone number',
  waiting_line_status_info_title: 'Status information',
  waiting_line_waiting: 'You will receive a message upon entering',
  waiting_line_waiting_estimated_disclaimer: '* estimated time',
  waiting_line_waiting_estimated_wait: 'Estimated wait time: {minutes} min',
  waiting_line_waiting_price: 'for only <Price>{price}</Price>',
  waiting_line_waiting_saved_time: 'Save {minutes} minutes*',
  waiting_line_waiting_ticket_number: 'Your pass number',
  waiting_line_waiting_upgrade: 'Upgrade to VIP',
  waiting_line_waiting_welcome: 'Welcome,',

  ...policiesEn,
  ...termsEn,
};

export default messages;
