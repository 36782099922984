import styled from 'styled-components';

interface NumberInputContainerProps {
  $disabled?: boolean;
}

export const NumberInputContainer = styled.div<NumberInputContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.normal};

  ${({ $disabled }) => $disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const Input = styled.input`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.custom.lightGray};
  padding: 0.65em 1em;
  border-radius: 0.5em;
  width: 3rem;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.big};

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {

    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
`;
