import { CardActions } from '@mui/material';
import styled from 'styled-components';

interface CardProps {
  $lessPadding: boolean
}

export const Card = styled.div<CardProps>`
  width: 100%;
  min-height: 400px;
  max-width: 600px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 8px 16px;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ $lessPadding }) => $lessPadding && `
    padding: 8px 0;
  `}
`;

export const CardTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  text-align: center;
`;

export const CardButtons = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 20px;
  margin: 0 -16px;
`;

export const CardButtonSpacer = styled.div`
  flex-grow: 1;
`;

export const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 1rem;
`;

interface StepTextProps {
  $active?: boolean
}

interface StepperProps {
  $hasTimer?: boolean
}

export const Stepper = styled.div<StepperProps>`
  background-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ $hasTimer }) => ($hasTimer ? '0' : '16px')};
  gap: 5%;
  border-radius: 2rem;
  padding: 0 16px;
  width: 100%;
  max-width: 500px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StepConnector = styled.div`
  width: 5%;
  height: 1px;
  background-color: white;
`;

export const StepIconContainer = styled.div`
  position: absolute;
  color: white; 
  transform: translateY(-50%);
`;

export const StepText = styled.p<StepTextProps>`
  color: ${({ theme, $active }) => ($active ? theme.palette.secondary.main : 'white')};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: bold;
`;

export const CenteredCardActions = styled(CardActions)`
  justify-content: center;
`;
