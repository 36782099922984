import { createContext } from 'react';

export interface IAuthenticatorContext {
  loggedIn: boolean
  logout: () => Promise<void>
}

export const DefaultAuthenticatorContext: IAuthenticatorContext = {
  loggedIn: false,
  logout: async () => {},
};

const AuthenticatorContext = createContext(DefaultAuthenticatorContext);
export default AuthenticatorContext;
