import { Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const CenteredContainer = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

export const TermsContainer = styled.div`
  overflow-y: auto;
  padding: 1rem 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const TitleTerms = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const CopyrightContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

export const TermContainer = styled.div`
  text-align: left;
  margin-right: 1rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const MailTo = styled(Button).attrs({
  variant: 'text',
  disableRipple: true,
})`
  padding: 0;
  border-radius: 0;
  user-select: text;
  text-align: center;
  text-decoration: underline;
  text-transform: none;
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
`;

export const TermTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const TermContent = styled(Typography)`
  text-align: justify;
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 2px;
  padding: 0 1rem;
  margin: 1rem 0;
`;
