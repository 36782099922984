import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Loading/Loader';
import { useGetObnlLazyQuery } from 'lib/graphql/graphql';
import { GetObnlItem } from 'lib/graphql/obnl/types';
import { SDG_ICONS } from 'lib/icons';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { CardButtons, CardButtonSpacer } from 'modules/Cart/styles';
import CartContext from 'providers/CartProvider/CartContext';
import { EventContext } from 'providers/EventProvider/EventContext';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedSecondaryButton, PrimaryButton, SecondaryButton } from 'styles/styles';

import {
  ChoiceDescription,
  ChoiceDescriptionContainer,
  ChoiceName,
  ChoiceObjective,
  ChoiceTitleContainer,
  ChoiceTitleDot,
  ImpactChoice,
  ImpactChoices,
  ImpactDescriptionAccent,
  ImpactDescriptionText,
} from './styles';

interface ImpactProps {
  onBack: () => void
  onNext: () => void
}

function Impact(props: ImpactProps) {
  const { onBack, onNext } = props;

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);
  const { obnls, refetchObnls } = event;
  const [choosing, setChoosing] = useState(false);
  const [selectedImpact, setSelectedImpact] = useState<GetObnlItem>();

  const { obnlId, setObnlId } = useContext(CartContext);

  const [getObnl, { loading }] = useGetObnlLazyQuery();
  const [obnlsData, setObnlsData] = useState<GetObnlItem[]>([]);

  useEffect(() => {
    refetchObnls();
  }, [refetchObnls]);

  useEffect(() => {
    if (obnls) {
      const fetchObnls = async () => {
        const promises = obnls.map((obnl) => getObnl({
          variables: {
            id: obnl,
          },
        }).then((result) => result.data?.getObnl));
        const results = await Promise.all(promises) as GetObnlItem[];
        setObnlsData(results);
      };
      fetchObnls();
    }
  }, [getObnl, event, refetchObnls, obnls]);

  const hasSelectedImpact = Boolean(selectedImpact);

  useEffect(() => {
    // Retrieve the selected impact from the context when going back to Impact page
    if (obnlId) {
      const obnl = obnlsData?.find((value) => (value?.id ?? '') === obnlId);
      if (obnl) setSelectedImpact(obnl);
    } else if (!obnlId && obnlsData && !hasSelectedImpact) {
      setSelectedImpact(obnlsData[0]);
    }
  }, [hasSelectedImpact, obnlId, obnlsData]);

  const onChoose = () => {
    refetchObnls();
    setChoosing(true);
  };

  const onChooseForMe = () => {
    const randomIndex = Math.floor(Math.random() * (obnlsData?.length ?? 0));
    const randomImpact = obnlsData?.[randomIndex];
    if (!randomImpact) return;

    setSelectedImpact(randomImpact);
    setObnlId(randomImpact.id);
    onNext();
  };

  const onChoosing = (obnl: NonNullable<GetObnlItem>) => {
    setObnlId(obnl.id);
    onNext();
  };

  const eventLanguage = event.languages[0] as Languages;
  const description = selectedImpact?.description?.[language] || selectedImpact?.description?.[eventLanguage] || '';

  return (
    <>
      <div>
        <ImpactDescriptionText>
          <FormattedMessage id="cart_impact_description" />
        </ImpactDescriptionText>
        <ImpactDescriptionAccent>
          <FormattedMessage id="cart_impact_follow" />
        </ImpactDescriptionAccent>
      </div>
      {!choosing && (
        <>
          <SecondaryButton onClick={onChooseForMe}>
            <FormattedMessage id="cart_choose_for_me" />
          </SecondaryButton>
          <OutlinedSecondaryButton onClick={onChoose}>
            <FormattedMessage id="cart_choose" />
          </OutlinedSecondaryButton>
        </>
      )}
      {choosing && (
        <Loader isLoading={loading}>
          <>
            <ImpactChoices>
              {obnlsData?.filter(Boolean).map((value) => (
                <ImpactChoice
                  key={value!.id}
                  $selected={value === selectedImpact}
                  onClick={() => setSelectedImpact(value)}
                >
                  <FontAwesomeIcon icon={SDG_ICONS[value!.cause]} size="3x" />
                </ImpactChoice>
              ))}
            </ImpactChoices>
            {selectedImpact !== undefined && selectedImpact !== null && (
              <ChoiceDescriptionContainer>
                <ChoiceTitleContainer>
                  <ChoiceObjective>
                    <FormattedMessage id={`obnl_${selectedImpact.cause}`} />
                  </ChoiceObjective>
                  <ChoiceTitleDot />
                  <ChoiceName>
                    {selectedImpact.name}
                  </ChoiceName>
                </ChoiceTitleContainer>
                <ChoiceDescription>
                  {description}
                </ChoiceDescription>
              </ChoiceDescriptionContainer>
            )}
          </>
        </Loader>
      )}
      <CardButtonSpacer />
      <CardButtons>
        <OutlinedSecondaryButton onClick={
          choosing
            ? () => setChoosing(false)
            : onBack
          }
        >
          <FormattedMessage id="general_back" />
        </OutlinedSecondaryButton>
        {choosing && (
          <PrimaryButton
            onClick={() => selectedImpact && onChoosing(selectedImpact)}
            disabled={selectedImpact === undefined}
          >
            <FormattedMessage id="general_next" />
          </PrimaryButton>
        )}
      </CardButtons>
    </>
  );
}

export default Impact;
