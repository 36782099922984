import styled from 'styled-components';

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: black;
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: black;
`;
