import { Grid } from '@mui/material';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
