import { FormattedMessage } from 'react-intl';

import { WhatsNextText, WhatsNextTitle } from './styles';

export interface WhatsNextProps {
  waitingLineDescription: string;
}

export default function WhatsNext({ waitingLineDescription }: WhatsNextProps) {
  return (
    <>
      <WhatsNextTitle>
        <FormattedMessage id="pass_what_next" />
      </WhatsNextTitle>
      <WhatsNextText>
        {waitingLineDescription}
      </WhatsNextText>
    </>
  );
}
