import { AlertColor, SnackbarOrigin } from '@mui/material';
import { createContext } from 'react';

export interface Feedback {
  anchor: SnackbarOrigin
  duration?: number | null
  content: JSX.Element
  severity: AlertColor
}
export type FeedbackFn = (feedback: Feedback) => void;

export const DefaultFeedbackContext: FeedbackFn = () => {};
const FeedbackContext = createContext(DefaultFeedbackContext);
export default FeedbackContext;
